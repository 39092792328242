@import "./global-theme";

:root {
  --top-bars-height: 0px;
}

body,
html {
  font-family: "Roboto", "Helvetica Neue", sans-serif !important;
  overflow: hidden;
  -webkit-box-shadow: 0 0 0 1000px white inset;
  box-shadow: 0 0 0 1000px white inset;

  input:-webkit-autofill,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  input:-webkit-autofill:hover,
  select:-webkit-autofill,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:hover,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
    -webkit-text-fill-color: #122b28;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

a {
  @include themed() {
    color: t("primary");
  }

  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }
}

.selected-message-view {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: none;
  outline: none;
  align-items: center;

  @include themed() {
    background: t("bg");
    color: t("text");
  }
}

.clear-selection-btn {
  background: none;
  border: none;

  @include themed() {
    color: t("primary");
  }
}

// popbox

.popbox {
  &.popbox-top-alert {
    background: #bfe9ff !important;
    width: 75%;
    height: auto;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .detail {
      font-size: 16px;
      text-align: center;
    }
  }
}

// RESPONSIVE
@media (max-width: 600px) {
  .desktop-view {
    display: none;
  }
}

@media (min-width: 992px) {
  .tablet-view {
    display: none;
  }
}

@media (min-width: 601px) {
  .mobile-view {
    display: none;
  }
}

// MODALS

.modal {
  z-index: 1300;

  .modal-content {
    .modal-header {
      .close-icon {
        cursor: pointer;
      }
    }
  }
}

.themed-modal {
  @include themed() {
    background: t("bgRHS");
  }

  h1,
  h3,
  h5,
  p,
  .themed-title,
  .skip-sending-multiple-content,
  .group-header-title,
  .title h4,
  .modal-header h5,
  .themed-static-text,
  .modal-title,
  .title {
    @include themed() {
      color: t("fontColor");
    }
  }

  .label,
  .skip-sending-multilple-sub-content,
  .message-tab {
    @include themed() {
      color: t("label");
    }
  }

  .custom-modal-steps-section-title,
  .themed-spinner,
  .themed-primary-color {
    @include themed() {
      color: t("primary");
    }
  }

  .description-text,
  .description-text span,
  .description-text-step2,
  .value-text,
  .numeric,
  .thread-item-title-text span,
  .delete-organization-container,
  .text-container,
  .modal-body {
    @include themed() {
      color: t("fontDarkGray");
    }
  }

  .themed-icon,
  .close-archived,
  .close-icon,
  .close,
  .icon-grey-dark {
    svg {
      @include themed() {
        path:not([fill="none"]) {
          fill: t("iconColor") !important;
        }
      }
    }
  }

  .contact-info,
  .modal-head,
  .people-count,
  .modal-title,
  .themed-text,
  .switch-modal-bottom-text,
  .themed-table-cell {
    @include themed() {
      color: t("iconColor");
    }
  }

  .radio-button-label {
    @include themed() {
      color: t("iconMidGray");
    }
  }

  .search-detail,
  .contact-tab-single {
    @include themed() {
      background: t("bgRHS");
    }
  }

  .btn-primary {
    @include themed() {
      color: t("bg");
      background-color: t("primary");
      border: 1px solid t("primary");
    }
    &:hover {
      @include themed() {
        background: t("primaryActionButtonHoverBg");
        background-color: t("primaryActionButtonHoverBg");
        border: 1px solid t("primaryActionButtonBg");
        color: t("primaryActionButtonHoverColor");
      }
    }
  }

  // MUI elements //

  .themed-table {
    th {
      @include themed() {
        color: t("text");
        background-color: t("bgRHS");
      }
    }

    td {
      @include themed() {
        border-color: t("tableDivider") !important;
      }
    }
  }

  .MuiInput-formControl {
    input {
      @include themed() {
        color: t("text");
      }
    }
  }

  .group_name {
    label {
      @include themed() {
        color: t("label");
      }
    }

    textarea {
      @include themed() {
        color: t("text");
      }
    }
  }

  .MuiInputBase-input,
  .switch-label {
    @include themed() {
      color: t("text");
    }

    svg {
      @include themed() {
        path:not([fill="none"]) {
          fill: t("iconColor") !important;
        }
      }
    }
  }

  .MuiInput-underline::after {
    @include themed() {
      border-bottom-color: t("primary") !important;
    }
  }

  .MuiRadio-colorSecondary {
    @include themed() {
      color: t("iconMidGray") !important;
    }
  }

  .Mui-checked {
    @include themed() {
      color: t("primary") !important;
    }
  }

  // END MUI Elements //
}

.modal-dialog {
  height: calc(100% - 16px);
}

.close-modal-icon {
  opacity: 1;
  outline: 0;
  position: absolute;
  right: 8px;
  top: 5px;

  svg {
    @include themed() {
      path:not([fill="none"]) {
        fill: t("iconColor") !important;
      }
    }
  }
}

button.close-modal-icon {
  padding: 0;
  background-color: initial;
  border: 0;
  appearance: none;

  svg {
    @include themed() {
      path:not([fill="none"]) {
        fill: t("iconColor") !important;
      }
    }
  }
}

button.close-modal-icon:focus {
  outline: none;
}

// tooltip

span[data-tooltip="tooltip"] {
  align-items: center;
  display: flex !important;
}

.tooltip {
  z-index: 9999999;

  &.show {
    opacity: 1;
  }

  @media (max-width: 991px) {
    &:not(.show-on-mobile-too) {
      display: none;
    }
  }

  .tooltip-inner {
    background: #ffffff;
    border: 1px solid #acacac;
    color: #122b28;
    font-size: 11px;
    line-height: 16px;
    padding: 0 8px;

    .warning {
      color: #fc7e00;
    }
  }

  ul {
    margin: 5px 0;
    padding-left: 24px;
    text-align: left;

    li {
      margin-left: -10px;

      span {
        position: relative;
        left: -5px;
      }
    }
  }
}

@media (max-width: 991px) {
  .show-on-mobile-too .tooltip {
    display: block;
  }
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #acacac;
  top: 1px;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::after,
.bs-tooltip-bottom .arrow::after {
  border-color: transparent;
  border-bottom-color: #fff;
  border-style: solid;
  border-width: 0 0.3rem 0.3rem;
  bottom: -1px;
  content: "";
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #acacac;
  bottom: 1px;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::after,
.bs-tooltip-top .arrow::after {
  border-color: transparent;
  border-style: solid;
  border-top-color: #fff;
  border-width: 0.4rem 0.4rem 0;
  content: "";
  left: 50%;
  position: absolute;
  top: -1px;
  transform: translate(-50%, 0);
}

// search-box

.search-box {
  flex-grow: 1;
  margin-right: 5px;
  max-width: 386px;

  .input-group {
    @include themed() {
      background: t("bgRHS");
    }

    border: 1px solid #e4e4e4;
    border-radius: 22px;

    ::placeholder {
      color: #707070;
    }

    .form-control {
      font-size: 1rem;
      font-weight: 400;
    }
  }
}

// avatar

.avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 400;
  color: #c9b4eb;
  margin: 0;
  min-width: 20px;
  background: $primary;
  overflow: hidden;

  &.number {
    border: 1px solid #707070;
    height: 32px;
    line-height: 32px;
    color: #707070;
    font-size: 1rem;
    font-weight: 400;
    min-width: 32px;
    width: auto;
    border-radius: 16px;
    padding: 0 10px;

    &.duplicate {
      color: #c0392b;
      border: 1px solid #c0392b;
      box-shadow: 4px 0 0 #c0392b;
    }
  }

  &.green {
    background: #abce68;
  }

  &.orange {
    background: #fa8d0e;
  }

  &.teal {
    background: #20cb96;
  }

  &.purple {
    background: #db83e6;
  }

  &.blue {
    background: #73baef;
  }

  &.ac-1 {
    background: #ff912a;

    &.light {
      background: rgba(32, 203, 150, 0.5);
    }

    svg path {
      fill: #ff912a;
    }
  }

  &.ac-2 {
    background: #91d63d;

    &.light {
      background: rgba(145, 214, 61, 0.5);
    }

    svg path {
      fill: #91d63d;
    }
  }

  &.group-avatar {
    background: #6c82bc;

    svg path {
      fill: #6c82bc;
    }
  }

  &.ac-3 {
    background: #4bbc2f;

    svg path {
      fill: #4bbc2f;
    }

    &.light {
      background: rgba(75, 188, 47, 0.5);
    }
  }

  &.ac-4 {
    background: #068d7f;

    &.light {
      background: rgba(6, 141, 127, 0.5);
    }

    svg path {
      fill: #068d7f;
    }
  }

  &.ac-5 {
    background: #2acaf6;

    &.light {
      background: rgba(42, 202, 246, 0.5);
    }

    svg path {
      fill: #2acaf6;
    }
  }

  &.ac-6 {
    background: #48a3eb;

    &.light {
      background: rgba(72, 163, 235, 0.5);
    }

    svg path {
      fill: #48a3eb;
    }
  }

  &.ac-7 {
    background: #465ab1;

    &.light {
      background: rgba(70, 90, 177, 0.5);
    }

    svg path {
      fill: #465ab1;
    }
  }

  &.ac-8 {
    background: #7a5fbc;

    &.light {
      background: rgba(122, 95, 188, 0.5);
    }

    svg path {
      fill: #7a5fbc;
    }
  }

  &.ac-9 {
    background: #ae6fc2;

    &.light {
      background: rgba(174, 111, 194, 0.5);
    }

    svg path {
      fill: #ae6fc2;
    }
  }

  &.ac-10 {
    background: #ee467b;

    &.light {
      background: rgba(238, 70, 123, 0.5);
    }

    svg path {
      fill: #ee467b;
    }
  }

  &.ac-11 {
    background: #ff5d34;

    &.light {
      background: rgba(255, 93, 52, 0.5);
    }

    svg path {
      fill: #ff5d34;
    }
  }

  &.ac-12 {
    background: #bce68a;

    &.light {
      background: rgba(188, 230, 138, 0.5);
    }

    svg path {
      fill: #bce68a;
    }
  }

  &.ac-13 {
    background: #a4dd96;

    &.light {
      background: rgba(164, 221, 150, 0.5);
    }

    svg path {
      fill: #a4dd96;
    }
  }

  &.ac-14 {
    background: #81c5be;

    &.light {
      background: rgba(129, 197, 190, 0.5);
    }

    svg path {
      fill: #81c5be;
    }
  }

  &.ac-15 {
    background: #7edff9;

    &.light {
      background: rgba(126, 223, 249, 0.5);
    }

    svg path {
      fill: #7edff9;
    }
  }

  &.ac-16 {
    background: #90c7f2;

    &.light {
      background: rgba(144, 199, 242, 0.5);
    }

    svg path {
      fill: #90c7f2;
    }
  }

  &.ac-17 {
    background: #a1abd7;

    &.light {
      background: rgba(161, 171, 215, 0.5);
    }

    svg path {
      fill: #a1abd7;
    }
  }

  &.ac-18 {
    background: #bbaedd;

    &.light {
      background: rgba(187, 174, 221, 0.5);
    }

    svg path {
      fill: #bbaedd;
    }
  }

  &.ac-19 {
    background: #f48faf;

    &.light {
      background: rgba(244, 143, 175, 0.5);
    }

    svg path {
      fill: #f48faf;
    }
  }

  &.ac-20 {
    background: #ff9d84;

    &.light {
      background: rgba(255, 157, 132, 0.5);
    }

    svg path {
      fill: #ff9d84;
    }
  }
}

.middle-avatar {
  width: 40px !important;
  height: 40px !important;
}

// button

.primary-button {
  background: $primary;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid $primary;
  padding: 9px 20px;

  &.button-small {
    padding: 7px 22px;
    display: inline-block;
  }

  &:focus {
    outline: 0;
  }
}

.red-button {
  background: #c0392b;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid #c0392b;
  padding: 9px 20px;

  &.button-small {
    padding: 7px 22px;
    display: inline-block;
  }

  &:focus {
    outline: 0;
  }
}

.outline-button {
  background: none;
  border-radius: 5px;
  color: #122b28;
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid #122b28;
  padding: 9px 20px;

  &:focus {
    outline: 0;
  }
}

// COLOR

svg.color-secondary {
  fill: $secondary;
}

// CHECKBOX

.custom-checkbox {
  min-height: 1px;
  padding-left: 0;

  .custom-control-label::after,
  .custom-control-label::before {
    position: absolute;
    width: 18px;
    height: 18px;
    left: auto;
    top: 50%;
    transform: translate(0, -50%);
  }

  .custom-control-label {
    font-size: 12px;
    font-weight: 400;
    color: #122b28;
  }

  .custom-control-label::before {
    background: transparent;

    @include themed() {
      border-color: t("primary");
    }

    border: 1px solid;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: transparent;

  @include themed() {
    border-color: t("primary");
  }

  border: 1px solid;
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: transparent;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  //new Icon
  background-image: url("../img/icons-new/general/check/check-thick-white.svg");
  background-size: 59%, 59%;
}

.no-padding {
  padding: 0 !important;
}

.no-boder {
  border: none;
}

.pl-30 {
  padding-left: 30px !important;
}

// Popover

.popover {
  position: static;
}

// CUSTOM COLLAPSE

.custom-collapse {
  .arrow-r {
    position: relative;

    &:after {
      content: "\f0d7";
      font-family: "FontAwesome";
      display: inline-block;
    }
  }

  .arrow-d {
    position: relative;

    &:after {
      content: "\f0d8";
      font-family: "FontAwesome";
      display: inline-block;
    }
  }
}

// Material Input

.input-field {
  &.material-style {
    margin-top: 40px;
    position: relative;

    ::placeholder {
      opacity: 0;
    }

    input {
      color: $color-text;
      font-family: $poppins;
      font-size: 1.38rem !important;

      &:focus {
        border-bottom: 1px solid $link;

        + label {
          color: #8f8f8f;
          font-size: 0.63rem !important;
          top: -15px;
          pointer-events: none;
        }
      }

      &:valid {
        border-bottom: 1px solid $link;

        + label {
          color: #8f8f8f;
          font-size: 0.63rem !important;
          top: -15px;
          pointer-events: none;
        }
      }

      &.input-has-value {
        border-bottom: 1px solid $link;

        + label {
          color: #8f8f8f;
          font-size: 0.63rem !important;
          top: -15px;
          pointer-events: none;
        }
      }
    }

    label {
      align-items: center;
      box-sizing: border-box;
      color: #b4b4b4;
      cursor: text;
      display: flex;
      font-family: $poppins;
      font-size: 1.38rem !important;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.2s ease-in-out;
    }
  }
}

// popbox modal

.iziModal-overlay {
  display: none;
  opacity: 0 !important;
}

.iziModal {
  .popbox-content {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
  }

  .modal-header {
    border-bottom: 0;
    flex-direction: column;

    .search-wrapper {
      margin-top: 25px;
      width: 100%;

      &.focused {
        border-color: $primary;
      }
    }
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }

  .custom-checkbox {
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    padding: 15px 3px;

    &:last-child {
      border-bottom: none;
    }

    .custom-control-label {
      font-size: 0.94rem;
      font-weight: 500;
      padding-left: 32px;

      &:after {
        border-radius: 0;
        left: 0;
      }

      &:before {
        border-radius: 0;
        left: 0;
      }

      .number {
        color: #b4b4b4;
        font-weight: 400;
        margin-left: 10px;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      @include themed() {
        background: t("primary");
      }
    }
  }

  .modal-footer {
    border-top: 0;

    .btn {
      min-width: 90px;
    }
  }
}

.dim-color:hover {
  background-color: #d4d4d4 !important;

  .name {
    color: gray !important;
  }
}

svg.icon-white {
  path,
  rect,
  circle {
    fill: #fff !important;
  }
}

svg.icon-path-dark {
  path {
    fill: $font-color-dark !important;
  }
}

svg.icon-purple {
  path,
  rect {
    fill: #6c37c9 !important;
  }
}

svg.icon-black,
.avatar.ac-2 svg.icon-black {
  transform: scale(1.05);

  path:not([fill="none"]),
  rect:not([fill="none"]),
  circle:not([fill="none"]) {
    fill: $color-text !important;
  }
}

svg.icon-grey {
  path:not([fill="none"]),
  rect:not([fill="none"]),
  circle:not([fill="none"]) {
    fill: #b4b4b4 !important;
  }
}

svg.icon-purple-dark {
  path:not([fill="none"]),
  rect:not([fill="none"]),
  circle:not([fill="none"]) {
    fill: #664f8e !important;
  }
}

svg.icon-grey2 {
  path,
  rect,
  circle {
    fill: #909090 !important;
  }
}

svg.icon-grey3 {
  path,
  rect,
  circle {
    fill: #95a5a6 !important;
  }
}

svg.icon-grey-dark {
  path:not([fill="none"]),
  rect,
  g,
  polyline {
    @include themed() {
      path:not([fill="none"]) {
        fill: t("iconMidGray") !important;
      }
    }

    // fill: #565656 !important;
  }
}

svg.icon-muted {
  path,
  rect,
  circle {
    fill: $text-muted !important;
  }
}

svg.icon-black {
  path:not([fill="none"]),
  rect:not([fill="none"]) {
    fill: #000000 !important;
  }

  path[fill="none"],
  rect[fill="none"] {
    fill: none !important;
  }
}

svg.icon-light-black {
  path:not([fill="none"]) rect:not([fill="none"]) {
    fill: #122b28 !important;
  }

  path[fill="none"],
  rect[fill="none"] {
    fill: none !important;
  }
}

svg.unknown-person-avatar {
  path:not([fill="none"]) {
    fill: #cacaca !important;
  }

  rect:not([fill="none"]) {
    fill: #cacaca !important;
  }

  path[fill="none"],
  rect[fill="none"] {
    fill: none !important;
  }
}

svg.icon-primary {
  path:not([fill="none"]),
  rect:not([fill="none"]),
  circle:not([fill="none"]) {
    fill: $primary !important;
  }
}

svg.icon-green {
  path,
  rect,
  circle {
    fill: #00c186 !important;
  }
}

.btn-primary,
.btn-outline-primary {
  &:hover {
    svg.icon-primary {
      path:not([fill="none"]),
      rect:not([fill="none"]) {
        fill: #fff !important;
      }
    }
  }
}

svg.icon-secondary {
  path,
  rect,
  circle {
    fill: $secondary !important;
  }
}

svg.icon-blue {
  path,
  rect,
  circle {
    fill: #73baef !important;
  }
}

svg.icon-madc {
  path,
  rect,
  circle {
    fill: #a574bd !important;
  }
}

// When-top-alert-exists

.with-alert {
  .sidebar {
    top: 100px;
  }

  .header {
    margin-top: 40px;
  }

  .page-wrapper {
    top: 40px;
  }

  .message-tab .message-list {
    padding-bottom: 100px;
  }
}

.hover-stroke-svg {
  &:hover {
    path {
      stroke: $primary;
      stroke-width: 0.5px;
    }
  }
}

svg.icon-block-sidebar {
  path:not([fill="none"]),
  rect {
    fill: #b4b4b4 !important;
  }
}

svg.icon-integration-sidebar {
  margin-top: 5px;

  path:not([fill="none"]),
  rect {
    fill: #807f82 !important;
  }
}

svg.icon-bg-grey {
  path:not([fill="none"]),
  rect:not([fill="none"]) {
    @include themed() {
      fill: t("text") !important;
    }
  }

  path[fill="none"],
  rect[fill="none"] {
    fill: none !important;
  }
}

.row-menus {
  position: absolute;
  right: 35px;

  @include themed() {
    background: t("bgRHS");
    box-shadow: 0px 0px 66px 28px t("bgRHS");
  }

  .themed-primary-color {
    @include themed() {
      color: t("primary");
    }
  }

  @media (max-width: $screen-md) {
    right: 15px;
  }
}

.group-table-row {
  @include themed() {
    border-top: 1px solid t("tableDivider");
    border-bottom: 1px solid t("tableDivider");
  }

  .group-table-menu-row {
    z-index: 10;
  }

  .for-archived-group-container {
    position: absolute;
    top: 2px;
    left: 0;
    height: 100%;
    width: 4px;
    border-radius: 3px;
  }

  .archived-container {
    @extend .for-archived-group-container;
    background: #fc7e00;
  }

  .not-archived-container {
    @extend .for-archived-group-container;
    background: #64c3e0;
  }

  .blocked-container {
    @extend .for-archived-group-container;
    background: #272727;
  }

  .contact-not-member-container {
    @extend .for-archived-group-container;

    @include themed() {
      background: t("bgRHS");
    }

    top: 0px;
  }

  .pco-pending-member-container {
    @extend .for-archived-group-container;
    background: #ffff57;
    top: 0px;
  }

  &.group-table-row-selected {
    @include themed() {
      background: t("bgRHS");
    }
  }

  &:hover {
    @include themed() {
      background: t("bgRHS");
    }
  }

  &.contact-is-not-member-row {
    opacity: 0.5;
  }

  .message-call-icons-placeholder {
    width: calc(1rem + 24px + 0.5rem + 17.16px);
  }

  .group-table-column-container {
    position: relative;
    min-height: 24px;
  }

  .group-member-avatar {
    margin-right: 6px;
    padding: 4px;
    display: flex;
    align-items: center;

    .avatar {
      display: flex;
      width: 36px;
      min-width: 36px;
      height: 36px;
      line-height: 36px;
      font-size: 1.06rem;
      padding: 2px;
      &.avatar-with-checkbox {
        width: 20px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin: 0 4px 0 10px;
      }
    }
  }

  .group-member-avatar-checkbox {
    margin-left: 4px;
  }

  .send-on-date {
    font-size: 0.8rem;
    @include themed() {
      color: t("fontMidGray");
    }
  }
}

.flexer-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexer-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flexer-row-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flexer-col-start {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 3rem;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-grabbing {
  cursor: grabbing !important;
}

.transparent .transparent:focus {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none !important;
}

.float-right {
  position: relative;
  right: 40px;
  text-align: center;
  display: inline;
}

.toggle-group-expand-btn {
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;

  &:focus {
    outline: none;
  }
}

.show-filters-button {
  &__show-filters {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    line-height: 1.1;
    @include themed() {
      background-color: t("primary");
      color: t("bg");
    }

    svg {
      transform: rotate(180deg);
    }
  }

  &__hide-filters {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.1;
    @include themed() {
      color: t("primary");
    }
  }
}

.width-zero {
  width: 0 !important;
}

.gray-color {
  @include themed() {
    color: t("fontMidGray");
  }
}

.themed-purple {
  @include themed() {
    color: t("primary");
  }
}

.purple-gray-color {
  color: #3e374a !important;
  stroke: #3e374a !important;
  border-color: #3e374a !important;
}

.light-gray-color {
  color: rgb(202, 202, 202) !important;
  stroke: rgb(202, 202, 202) !important;
  border-color: rgb(202, 202, 202) !important;
}

.bg-yellow {
  background-color: yellow;
}

.active-item {
  background-color: orange !important;
  z-index: 50;
}

.round-btn {
  border-radius: 18px;
}

.btn-outline-primary {
  @include themed() {
    color: t("primary") !important;
    border-color: t("primary") !important;
  }

  &:not(.disable):hover {
    color: white !important;

    @include themed() {
      background-color: t("primary") !important;
    }
  }
}

.disable {
  @include themed() {
    color: t("fontMidGray") !important;
    border-color: t("fontMidGray") !important;
  }
}

.search-box-spinner {
  width: 17px;
  height: 17px;
  border-width: 1.5px;
  position: absolute;
  z-index: 9999;

  @include themed() {
    color: t("primary") !important;
  }
}

.lhs-thread-actions-btn {
  background-color: #6c37c9;
  border: none;
  color: white;
  border-radius: 5px;

  &:focus {
    outline: none !important;
  }
}

.popover-select-box-container {
  .popover {
    min-width: 150px;
  }

  .active {
    font-weight: bold;
    color: $primary;
  }
}

.refreshing-box {
  text-align: center;
  padding-top: 5px;
  color: #6c37c9;
}

.top-banner {
  height: 27px;
  width: 100%;
}

.top-banner-wrapper {
  height: 27px !important;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: $screen-md) {
    min-height: 25px !important;
    height: auto !important;
    font-size: 12px !important;
  }
}

.hub-list-wrapper {
  padding-top: 45px;
}

.account-issue-bar-close-icon {
  position: absolute;
  right: 15px;
}

.main-app-container {
  .chat-area {
    height: 97vh !important;

    @media (max-width: $screen-md) {
      height: 100vh !important;
    }
  }

  .chat-window {
    height: 97vh !important;

    @media (max-width: $screen-md) {
      height: 100% !important;
    }
  }

  .group-info {
    height: 97% !important;

    @media (max-width: $screen-md) {
      height: 100% !important;
    }
  }

  .fixed-header {
    @media (max-width: $screen-md) {
      margin-top: 27px;
    }
  }

  .dashboard-header {
    @media (max-width: $screen-md) {
      margin-top: 0px !important;
    }
  }

  .thread-view-people-messages {
    height: 97% !important;

    @media (max-width: $screen-md) {
      height: 100% !important;
    }
  }

  .people-thread-view-body > div {
    height: 97% !important;

    @media (max-width: $screen-md) {
      height: 100% !important;
    }
  }

  .list-scrollbar-container > div {
    max-height: calc(97vh + 15px) !important;

    @media (max-width: $screen-md) {
      max-height: calc(90vh + 15px) !important;
    }
  }

  .my-signature-scrollbar-container > div {
    max-height: calc(97vh - 250px + 15px) !important;
  }

  .rhs-settings-container {
    .close-icon {
      top: 35px !important;
    }
  }

  .admin-settings-users-rhs {
    .menu-icon {
      top: 32px !important;
    }
  }

  .search-filter {
    @media (max-width: $screen-md) {
      margin-top: 27px !important;
    }
  }

  .hub-list-wrapper {
    @media (max-width: $screen-md) {
      padding-top: 70px !important;
    }
  }

  .top-panel-actions {
    @media (max-width: $screen-md) {
      padding-top: 40px !important;
    }
  }

  .right-panel {
    @media (max-width: $screen-md) {
      height: 100vh !important;
    }
  }

  .my-sginature-side-bar-back-btn {
    @media (max-width: $screen-md) {
      top: 35px !important;
    }
  }

  .menu-submenu-sidebar {
    @media (max-width: $screen-md) {
      margin-top: 30px;
    }
  }
}

.top-bar-link-text {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

.spinner-text {
  @include themed() {
    color: t("primary");
  }
}

.highlight-overlay-active {
  z-index: 1310;
  background: #f8f8f8;
  border-radius: 6px;
  position: relative;

  .collapsible-header {
    border: none !important;
  }

  .collapsible-body a {
    border: none !important;
  }
}

.highlight-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1300;
}

.collapsible-header {
  cursor: pointer;

  .level2-collapse {
    cursor: pointer;
  }
}

ic-anchor-point {
  display: none !important;
}

.w-fit {
  width: fit-content;
}

.pointer-events-none {
  pointer-events: none;
}

.quick-filter {
  cursor: pointer !important;
  @include themed() {
    color: t("primary") !important;
  }

  .underlined-text {
    text-decoration: underline !important;
  }

  &.active {
    padding: 0px 5px !important;
    margin-left: 2px;
    @include themed() {
      border: 1px solid t("primary") !important;
    }

    border-radius: 5px !important;
  }
}

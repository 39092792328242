@import "../../base/global-theme";

.display-preference-modal {
  .modal-dialog {
    max-width: 1000px;
  }
  .modal-content {
    overflow: hidden;
    width: 100%;
    height: 80vh;
  }

  .modal-title {
    font-size: 1rem;
  }

  .nav-item {
    @media (max-width: $screen-sm) {
      width: 100%;
    }
  }

  .expand-sidebar {
    @media (max-width: $screen-sm) {
      width: 100% !important;
    }
  }

  .display-preference-filters-container {
    width: 30%;
    padding: 20px 10px 20px 10px;

    @media (max-width: $screen-sm) {
      width: 100%;
    }

    &.hide-filters {
      @media (max-width: $screen-sm) {
        display: none;
      }
    }

    .sub-heading {
      font-size: 1rem;
      @include themed() {
        color: t("fontColor");
      }
    }

    .cancel-text {
      font-size: 12px;
      cursor: pointer;
      @include themed() {
        color: t("primary");
      }
    }

    .search-wrapper {
      height: 25px !important;
      display: flex;
      align-items: center;
      width: 70% !important;

      .search-input {
        font-size: 13px;
      }
    }

    .display-preference-filter {
      @include themed() {
        color: t("text");
      }
      font-size: 15px;
      padding: 5px 0px 5px 15px;
      margin-bottom: 15px;

      svg,
      path,
      g {
        @include themed() {
          fill: t("text");
        }
      }

      &.active,
      &:hover {
        font-weight: 500;
        color: #202020;
        @include themed() {
          background-color: t("modalMenuBg") !important;
        }
        border-radius: 50px;
        width: 100% !important;
        padding-left: 10px;
        @include themed() {
          color: t("fontColor") !important;
        }
        svg,
        path,
        g {
          @include themed() {
            fill: t("fontColor") !important;
          }
        }
      }
    }
  }

  .display-preference-content-container {
    width: 80%;
    @media (max-width: $screen-sm) {
      width: 100%;
    }

    &.hide-content {
      @media (max-width: $screen-sm) {
        display: none;
      }
    }

    .display-preference-content-inner-wrapper {
      padding: 25px 10px 35px 30px;

      &.with-align-center-on-small-screens {
        @media (max-width: $screen-md) {
          justify-content: center;
          display: flex;
          align-items: center;
          padding: 0px;
        }
      }
    }

    .display-preference-content-heading {
      font-size: 1rem;
      @include themed() {
        color: t("fontDarkGray");
      }
    }
  }

  .display-preference-themes-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media (max-width: $screen-sm) {
      justify-content: center;
    }

    .display-preference-themes-selector-wrapper {
      margin-right: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 10px;
    }

    .visual-label {
      font-size: 0.75rem;
      @include themed() {
        color: t("fontDarkGray");
      }
    }

    .app-behavior-switch {
      span[label] {
        font-size: 16px;
      }
    }

    .theme-setting-select {
      width: 50%;
      .MuiInputBase-root {
        font-weight: 500;
      }
    }
  }

  .default-label {
    @extend .label-fields;
  }

  .default-page-select {
    width: 85% !important;
  }

  .app-settings-select {
    width: 85% !important;
  }

  .group-preference-card-container {
    .card-info-container {
      padding-bottom: 30px;
    }
  }

  .note-text {
    font-size: 1rem;
    @include themed() {
      color: t("fontDarkGray");
    }
  }

  .launch-icon {
    svg,
    path,
    g {
      @include themed() {
        fill: t("primary") !important;
      }
    }
  }

  .display-preference-card-container {
    width: 50% !important;
    @media (max-width: $screen-md) {
      width: 80% !important;
      justify-content: start;
    }
  }

  .voice-preference-card-container {
    @media (max-width: $screen-md) {
      justify-content: flex-start;
    }

    .left-column {
      margin-right: 0px !important;
      max-width: fit-content;
    }
  }
}

.box-selector {
  border: 1px solid #d7d7d7;
  font-size: 0.8rem;
  border-radius: 5px;
  padding: 6px;
  width: 145px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include themed() {
    color: t("fontDarkGray");
  }

  .theme-icon {
    svg,
    path,
    g {
      @include themed() {
        fill: t("text");
      }
    }
  }

  &.active,
  &:hover {
    @include themed() {
      border: 1px solid t("inverseFontColor");
    }
    font-weight: 500;
    @include themed() {
      color: t("fontColor");
    }

    .theme-icon {
      svg,
      path,
      g {
        @include themed() {
          fill: t("fontColor");
        }
      }
    }
  }
}

/*------------------------------------------------------------------
[7. Sidebar]
*/
@import "../base/global-theme";

.drawer-active .sidebar-menu > ul > li > a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 9px;

  &.active .icon-wrapper {
    margin-right: 5px;
    width: 42px;
  }
}

.sidebar {
  top: 0px;
  width: 60px;
  z-index: 1290;
  @include themed() {
    background: t("leftSideMenu") !important;
    border-right: 1px solid t("sideBarDividerColor");
  }
  bottom: 0;
  margin-top: 0;
  position: fixed;
  left: 0;
  transition: none;
  overflow-x: visible;

  @media (max-width: $screen-md) {
    width: 100%;
    top: auto !important;
    right: 0;
    left: auto;
  }

  .slimScrollBar {
    opacity: 0 !important;
  }

  .slimScrollDiv {
    &:hover {
      .slimScrollBar {
        opacity: 0.4 !important;
      }
    }
  }

  a {
    &.active {
      svg {
        path:not([fill="none"]) {
          fill: #ffffff;
        }

        circle {
          fill: #ffffff;
        }
      }
    }

    &.hovered {
      svg {
        path:not([fill="none"]) {
          fill: #ffffff;
        }

        circle {
          fill: #ffffff;
        }
      }
    }

    // &.hover {
    //   svg {
    //     path {
    //       fill: #C9CECD;
    //     }
    //   }
    // }

    &:hover {
      svg {
        path:not([fill="none"]) {
          @include themed() {
            fill: t("navbarIconHover");
          }
        }
      }
    }

    svg {
      path:not([fill="none"]) {
        fill: #664f8e;
      }
    }
  }
}

.expand-sidebar {
  width: 193px;
}

.sidebar-inner {
  height: 100%;
  position: relative;

  .sidebar-inner-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 9999;
    top: -15px;
    width: 100%;
    height: calc(100% + 15px);
    position: absolute;
  }

  .nav-tabs {
    border: 0;
    display: flex;
    justify-content: center;
    @include themed() {
      border-bottom: 1px solid t("sideBarDividerColor");
    }
    @media (max-width: $screen-md) {
      justify-content: space-around;
      width: 82%;
      border-bottom: none !important;
    }
  }

  .dashboard-bottom-tab {
    @media (max-width: $screen-md) {
      height: 65px;
    }
  }

  .mobile-top {
    // background: $primary;
    padding-bottom: 20px;

    li {
      a {
        align-items: center;
        color: #fff;
        display: flex;

        &.double-credit {
          svg {
            path {
              fill: #c8ff00;
            }
          }
        }
      }

      .nav-link {
        &.hovered .toggle-icon {
          .outlined {
            display: none;
          }

          .filled {
            display: inline-block;
          }
        }

        span {
          margin-left: 0;
        }

        .toggle-icon {
          margin-left: 0;
          padding: 0;
          position: relative;

          .filled {
            display: none;
          }

          svg path {
            fill: #fff;
          }
        }
      }
    }

    .badge-wrapper {
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      height: 60px;
      line-height: 60px;
      padding: 0 15px;
      margin-right: 20px;

      .badge.badge-pill {
        height: 18px;
        line-height: 18px;
        padding: 0;
        position: absolute;
        right: -10px;
        top: 10px;
        width: 18px;
      }
    }

    .primary-badge {
      background-color: #9253ff;
    }
  }
}

.sidebar-menu {
  position: relative;
  height: 100vh;
  &.sidebar-menu-desktop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  ul {
    background-color: transparent;
    font-size: 0.875rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    margin-top: 0;
    // padding-top: 20px;

    li {
      width: 100%;
    }

    .nav-item {
      .item-btn {
        border: none;
        outline: none;
        background: transparent;
        padding: 0px;
        width: 24px;
        height: 24px;
        margin-bottom: 24px;
        .more-icon {
          @include themed() {
            svg g circle {
              fill: t("menuFontColor") !important;
            }
            color: t("menuFontColor");
          }
        }

        &:hover {
          .more-icon {
            svg g circle {
              fill: #fff;
            }
          }
        }
      }
    }
    @media (max-width: $screen-md) {
      padding-top: 10px;
    }
  }

  .middle-container {
    .middle-overlay {
      position: absolute;
      top: calc(50% - 32px);
      height: 66px;
      width: 66px;
      left: 14px;
      z-index: 100050;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.75);
    }
    &.middle-container-absolute {
      position: relative;
      @media (min-height: 730px) {
        position: absolute;
        top: 50%;
      }
      @media (max-width: $screen-md) {
        top: 0;
        right: 5%;
      }
    }

    .add-icon {
      border: none;
      outline: none;
      background: transparent !important;
      margin-left: 27px;
      padding-left: 0px;
      padding-right: 0px;
      position: relative;

      &::after {
        width: 66px;
        height: 66px;
        background-color: transparent;
        @include themed() {
          border: 15px solid t("leftSideMenu");
        }
        border-radius: 50%;
        position: absolute;
        top: -14px;
        left: -15px;
        content: "";
      }

      svg {
        height: 36px;
        width: 36px;
        @media (max-width: $screen-xxs) {
          width: 30px;
        }

        path {
          fill: #6c37c9;
        }

        g path {
          fill: #fff;
        }
      }
    }

    .sidebar-expanded-add-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        border: none !important;
      }
      .icon-label {
        font-size: 14px;
        margin-bottom: 0px;
        font-weight: 500;
        cursor: pointer;
        @include themed() {
          color: t("menuFontColor");
        }
      }
    }
  }

  .extra-menus-container {
    @include themed() {
      border-bottom: 1px solid t("sideBarDividerColor");
    }
    &.extra-menus-container-space {
      @media (max-height: 600px) {
        padding-bottom: 20px;
      }
    }
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    // position: absolute;
    // bottom: 0;
  }

  .bottom-container {
    margin: 0px 11px 21px 15px;
    display: flex;
    flex-direction: column;
    &.minimized {
      justify-content: center;
      align-items: center;
    }
    .bottom-container-collapse {
      cursor: pointer;
      margin-top: 27px;
      text-align: end;
      font-size: 12px;
      @include themed() {
        color: t("sideBarColor");
      }
      &.minimized {
        margin-top: 16px;
        margin-left: -8px;
      }
    }
    &__expand {
      @include themed() {
        color: t("inverseFontColor");
      }
    }

    .expand-collapse-icon {
      svg {
        @include themed() {
          path {
            fill: t("primary");
            stroke: t("primary");
          }
        }
      }
    }
  }

  @media (max-width: $screen-md) {
    height: auto;
  }
}

.add-new-popover .popover {
  width: auto;
  left: 0px !important;
}

.side-menu-popover .popover {
  left: 0px;
  min-width: 168px;
  max-width: 400px;
  z-index: 99010;
  .popover-header {
    font-family: "Roboto-Bold", "Helvetica Neue", sans-serif;
    font-size: 16px;
    color: #202020;
  }

  .popover-body {
    padding-top: 0px;
    padding-bottom: 8px;
    a {
      color: #202020;
      font-family: Roboto, "Helvetica Neue", sans-serif;
      font-size: 14px;
      display: block;
      padding-top: 12px;
      padding-bottom: 3px;

      span.menu-icon {
        padding-left: 4px;
        width: 32px;
        display: inline-block;
        margin-right: 5px;
      }
    }
    .sync-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      font-size: 14px;
      padding-top: 12px;
      padding-bottom: 3px;
      svg {
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
  &[aria-expanded="true"] {
    background: yellow !important;
  }
  .svg-caption {
    font-weight: bold;
  }
}

.sidebar-unread-popover .popover {
  left: 315px !important;
}

.sidebar-more-menu-popover .popover {
  left: 184px !important;
  position: relative;
  min-width: 168px;
  max-width: 400px;
  z-index: 99010;
  .popover-header {
    font-family: "Roboto-Bold", "Helvetica Neue", sans-serif;
    font-size: 16px;
    @include themed() {
      background-color: t("bg") !important;
      color: t("fontColor") !important;
    }
  }

  .popover-body {
    padding-top: 0px;
    padding-bottom: 8px;
    @include themed() {
      background-color: t("bgRHS") !important;
    }
    a {
      @include themed() {
        color: t("fontColor") !important;
      }
      font-family: Roboto, "Helvetica Neue", sans-serif;
      font-size: 14px;
      display: block;
      padding-top: 12px;
      padding-bottom: 3px;

      span.menu-icon {
        padding-left: 4px;
        display: inline-block;
        svg {
          height: 28px;
          width: 28px;
        }
      }
    }
  }
  &[aria-expanded="true"] {
    background: yellow !important;
  }
}

.sidebar-integrations-popover .popover {
  left: 280px !important;
  position: relative;
  min-width: 168px;
  max-width: 400px;
  z-index: 99010;

  .popover-body {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    @include themed() {
      background-color: t("bgRHS") !important;
    }
    a {
      @include themed() {
        color: t("fontColor") !important;
        &:hover {
          color: t("primary");
        }
      }
      padding-top: 10px;
      padding-bottom: 3px;
    }
  }
}

.sidebar-list-item {
  margin-bottom: 13px;
  cursor: pointer;
  &.minimized {
    margin-bottom: 6px;
  }
  .sidebar-list-item-more-arrow {
    display: inline;
    svg {
      margin-left: 9px;
    }
  }
  &.create-new-item {
    &.active,
    &:hover {
      @include themed() {
        color: t("primary") !important;
      }
    }
    &.active {
      font-weight: 600;
    }
  }
}

.sidebar-menu li a {
  display: block;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  position: relative;
  transition: 0s;
  border-radius: 20px;
  margin: 0px 20px 0px 5px;
  height: 27px;
  &.minimized {
    margin: 0px 10px 0px 10px;
    height: auto;
    @media (max-width: $screen-md) {
      margin: 0px;
      padding: 0px 10px;
    }
    @media (max-width: $screen-xxs) {
      padding: 0px;
    }
  }
  &.active {
    @include themed() {
      background: t("sideBarBackgroundSelected") !important;
      color: t("sideBarColorSelected") !important;
    }
    svg path:not([fill="none"]) {
      @include themed() {
        fill: t("sideBarColorSelected");
      }
    }
  }
  &:hover {
    @include themed() {
      background: t("sideBarBackgroundHover");
      color: t("sideBarColorHover");
    }
    svg path:not([fill="none"]) {
      @include themed() {
        fill: t("sideBarColorHover");
      }
    }
  }
  @include themed() {
    color: t("sideBarColor");
  }
}

@media (max-width: 992px) {
  .sidebar-menu li a span {
    opacity: 1;
    /* margin-left: 10px; */
  }
}

.sidebar-menu li a img {
  margin-right: 0;
  font-size: 0.875rem;
  max-width: 20px;
  max-height: 20px;
  transition: all 0.4s ease;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.sidebar-menu li a img.white-icon {
  opacity: 0;
}

.sidebar-menu li a .icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  background: transparent;
  padding: 5px 0px;

  @media (max-width: $screen-xxs) {
    height: 35px;
    width: 35px;
  }

  span.notification-badge-undelivered {
    display: none;
  }
  span.notification-badge-unread {
    display: none;
  }
  @include themed() {
    svg path:not([fill="none"]) {
      fill: t("sideBarColor");
    }
    svg g:not([fill="none"]) {
      fill: t("sideBarColor");
    }
    .stroke-icon {
      g:not([stroke="none"]) {
        stroke: t("sideBarColor");
      }
    }
  }
}

.sidebar-menu li a .sidebar-expanded-list-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  background: transparent !important;
}

.sidebar-expanded-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  label {
    margin-bottom: 0px !important;
    cursor: pointer;
    white-space: noWrap;
  }
  .sidebar-expanded-list-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 9px;
    margin-left: 13px;
  }
}
.sidebar-expanded-list-active {
  border-radius: 20px;
  padding: 0 8px;
  margin: 0;
  @include themed() {
    background-color: t("sideBarBackgroundSelected");
    color: t("sideBarTextHighlight");
  }
  &.sidebar-expanded-list {
    margin: 0 8px 0 -8px;
  }
}

.sidebar-menu li a .icon-with-status {
  position: relative;
  span.notification-badge-undelivered {
    background-color: #ff5800;
    position: absolute;
    top: 4px;
    right: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
  }

  span.notification-badge-unread {
    background-color: #ffffff;
    position: absolute;
    top: 4px;
    right: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
  }
}

.icon-with-status {
  position: relative;
  span.notification-badge-undelivered {
    background-color: #ff5800;
    position: absolute;
    top: 4px;
    right: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
  }

  span.notification-badge-unread {
    background-color: #ffffff;
    position: absolute;
    top: 4px;
    right: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
  }

  span.notification-badge-blue {
    background-color: #64c3e0;
    position: absolute;
    top: 4px;
    right: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
  }
}

.sidebar-menu li a.active img.white-icon,
.sidebar-menu li a:hover img.white-icon {
  opacity: 1;
}

.sidebar-menu li a.active img.dark-icon,
.sidebar-menu li a:hover img.dark-icon {
  opacity: 0;
}

.sidebar-menu li a > .badge {
  margin-top: 10px;
  color: #fff;
  font-weight: 700;
}

.sidebar-menu li a i {
  font-size: 1rem;
  margin-left: 10px;
  margin-right: 10px;
}

.sidebar-menu li a i.back-icon {
  margin-left: 0;
}

.sidebar-menu li.active a {
  color: #00bf6f;
  background-color: #f3f3f3;
}

.menu-title {
  color: #000;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 40px;
  min-height: 40px;
  padding: 0 20px;
  text-transform: uppercase;
}

.menu-title > i {
  float: right;
  line-height: 40px;
}

.sidebar-menu li.menu-title a {
  color: #ff9b44;
  display: inline-block;
  float: right;
  padding: 0;
}

.sidebar-menu li.menu-title a.add-user-icon {
  background-color: #eee;
  border-radius: 24px;
  color: #bbb;
  line-height: 24px;
  min-height: 24px;
  width: 24px;
  text-align: center;
  margin-top: 6px;
}

.sidebar-menu li.menu-title a.add-user-icon:hover {
  background-color: #00bf6f;
  color: #fff;
}

.sidebar-menu li.menu-title a.add-user-icon i {
  font-size: 0.875rem;
  margin-left: 0;
  margin-right: 0;
}

.sidebar-menu li.menu-title a.btn {
  color: #fff;
  display: block;
  float: none;
  font-size: 0.9375rem;
  line-height: inherit;
  margin-bottom: 15px;
}

.sidebar-menu li.menu-title a.btn:hover {
  color: #fff;
}

.sidebar-menu ul ul a.active {
  color: #00bf6f;
  text-decoration: underline;
}

.sidebar-menu .credits {
  display: block;
  padding: 0 0px 10px 20px;
  font-size: 0.75rem;
}

.mobile-user-menu {
  color: #fff;
  display: none;
  font-size: 1.5rem;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 60px;
  z-index: 10;
}

.mobile-user-menu > a {
  color: #fff;
  padding: 0;
  line-height: 70px;
}

.mobile-user-menu > a i {
  font-size: 29px;
}

.mobile-user-menu > .dropdown-menu > a {
  line-height: 23px;
}

.profile-rightbar {
  display: none !important;
  color: #00bf6f;
  font-size: 1.625rem;
  margin-left: 15px;
}

.mobile_btn {
  display: none;
}

#sidebar-menu ul ul a {
  display: block;
  padding: 0 20px 0 60px;
}

#sidebar-menu ul ul ul a {
  padding-left: 80px;
}

#sidebar-menu ul ul ul ul a {
  padding-left: 100px;
}

#sidebar-menu ul ul {
  background-color: #f3f3f3;
}

.sidebar-menu > ul > li {
  position: relative;
  white-space: nowrap;
  @media (max-width: $screen-xxs) {
    margin-left: 0px;
  }
}

.sidebar-menu > ul > li > a {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  cursor: pointer;
  @media (max-width: $screen-md) {
    height: auto;
    margin-bottom: 0px;
  }
}

.table-content .message-unread-info {
  background-color: #2a2a2a;

  span {
    color: #808080;
    font-size: 12px;
  }

  a {
    color: #d9d9d9 !important;
    font-size: 10px;
    border: 1px solid #808080;
    border-radius: 2px;
    padding: 1px 6px;
  }
}

.sidebar-menu .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  position: absolute;
  right: 20px;
  display: inline-block;
  font-family: "FontAwesome";
  text-rendering: auto;
  line-height: 40px;
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.sidebar-menu .menu-arrow:before {
  content: "\f105";
}

.sidebar-menu li a.subdrop .menu-arrow {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.noti-dot:before {
  content: "";
  width: 5px;
  height: 5px;
  border: 5px solid #00bf6f;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #00bf6f;
  z-index: 10;
  position: absolute;
  right: 37px;
  top: 17px;
}

.noti-dot:after {
  content: "";
  border: 4px solid #00bf6f;
  background: transparent;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  height: 24px;
  width: 24px;
  -webkit-animation: pulse 3s ease-out;
  -moz-animation: pulse 3s ease-out;
  animation: pulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: 10px;
  right: 30px;
  z-index: 1;
  opacity: 0;
}

// .sidebar-overlay {
//   background-color: rgba(0, 0, 0, 0);
//   display: none;
//   left: 0;
//   position: fixed;
//   height: 100%;
//   top: 0;
//   overflow: hidden;
//   width: 100%;
//   z-index: 99008;
// }

// .sidebar-overlay.opened {
//   display: block;
// }

.sidebarExpand {
  top: 0px;
  width: 0px;
  z-index: 99009;
  background-color: transparent;
  bottom: 0;
  margin-top: 0;
  position: absolute;
  left: 60;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.sidebar-menu .sidebar-menu-create-new {
  width: 100%;
  padding: 0px 20px 0px 10px;
  margin-bottom: 30px;
  margin-top: 30px;
  &.minimized {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sidebar-menu-divider {
  @include themed() {
    border: 1px solid t("sideBarDividerColor");
  }
}

.sidebar-menu .sidebar-menu-top {
  display: flex;
  flex-direction: column;
  height: calc(100% - 166px);

  @media (max-width: $screen-md) {
    flex-direction: row;
  }
}

.sidebar-menu-new-chip {
  color: white !important;
  background-color: #4caf50 !important;
  font-size: 0.5rem !important;
  height: 12px !important;
  font-weight: 400 !important;
  margin-left: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  .sidebar-menu-new-chip-label {
    padding-left: 4px !important;
    padding-right: 4px !important;
    padding-top: 1px !important;
  }
}

body {
  &.has-minimize-bar {
    .header {
      position: fixed;
      top: 30px;
    }
    .main-tab-content {
      margin-top: 30px;
    }

    .sidebar {
      top: 90px;
    }
  }
}

.main-tab-content {
  margin-top: 30px;
  @include themed() {
    background: t("bg");
  }
}

.call-minimize-bar {
  background-color: #000;
  height: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;

  .detail {
    margin: auto;
  }

  .right-action {
    margin-right: 22px;
  }

  .decline {
    align-items: center;
    background-color: #dd0000;
    border-radius: 50%;
    display: inline-flex;
    height: 18px;
    justify-content: center;
    width: 18px;
    vertical-align: middle;

    i {
      color: #fff;
      font-size: 12px;
    }
  }

  .calling-details {
    font-weight: 600;
    margin-right: 35px;
  }

  .duratiion-details {
    font-weight: 600;
    margin-right: 25px;
  }
}

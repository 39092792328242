@import "../base/global-theme";

.hub-thread-message-form-wrapper {
  @include themed() {
    background: t("bg");
  }
  .hub-thread-message-tab {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    // color: #3e374a;
    .hub-thread-message-tab-title {
      padding: 4px 4px 6px 4px;
      margin-bottom: -2px;
      @include themed() {
        color: t("iconMidGray");
        background: t("bgRHS");
        border-top: 1px solid t("lightToneContrast");
        border-right: 1px solid t("lightToneContrast");
      }
    }
    .hub-thread-short-code-warning {
      display: inline-block;
      background: #ffff57;
      border-radius: 5px;
      padding: 2px 4px 2px 4px;
      margin: 4px 0px 4px 4px;
    }
  }
  .hub-thread-message-form-container {
    @include themed() {
      background: t("bgRHS");
      border-top: 1px solid t("lightToneContrast");
    }

    padding: 10px 32px 19px 25px;
    .hub-thread-features-container {
      margin-bottom: 16px;
      margin-left: 32px;
    }

    .hub-thread-input-container {
      display: flex;
      align-items: center;
      svg.themed-gray-icon,
      .date-time-picker svg {
        @include themed() {
          path:not([fill="none"]) {
            fill: t("iconMidGray") !important;
          }
        }
      }
      svg#Send {
        @include themed() {
          path:not([fill="none"]) {
            fill: t("primary") !important;
          }
        }
      }
    }
    .hub-thread-above-input-container {
      display: flex;
      justify-content: space-between;
      padding-left: 40px;
      margin-bottom: 5px;
      width: 80%;
      .number-container {
        font-size: 11px;
        @include themed() {
          color: t("iconMidGray");
        }
        .color-primary {
          @include themed() {
            color: t("primary") !important;
          }
        }
      }
      @media (max-width: $screen-sm) {
        width: 100%;
        padding-left: 0px;
      }
    }
  }

  .text-area-message {
    border-radius: 17px;
    @include themed() {
      background: t("textArea");
      border: 1px solid t("textArea");
    }
    padding: 3px 35px 3px 10px;
    width: 100%;
    overflow: hidden;
    display: flex;
    position: relative;

    .disabled-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 17px;
      background: #d9d9d9;
      z-index: 10;
      opacity: 0.7;
    }

    .textarea-auto-resize,
    .highlights {
      resize: none;
      border: none;
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      font-size: 15px;
    }
    .textarea-auto-resize:focus {
      outline: none;
    }
    .textarea-auto-resize::placeholder {
      padding-top: 4px;
      font-size: 11px;
    }
    .textarea-auto-resize {
      @include themed() {
        background: t("textArea");
        color: t("fontColor");
      }
    }
    .backdrop {
      position: absolute;
      top: 5px;
      left: 10px;
      z-index: 1;
      height: calc(100% - 4px - 6px);
      width: calc(100% - 4px - 45px);
      background-color: transparent;
      overflow: auto;
      pointer-events: none;
      transition: transform 1s;
    }

    .text-area-message {
      position: absolute;
      z-index: 2;
    }

    .smile-icon {
      position: absolute;
      right: 10px;
      cursor: pointer;
    }

    .smile-icon-disabled.smile-icon {
      path {
        fill: #ababab;
      }
    }

    .highlights {
      white-space: pre-wrap;
      word-wrap: break-word;
      color: transparent;
      overflow: hidden;
    }

    .signature-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      font-size: 15px;
      padding: 0px 35px 5px 13px;
      @include themed() {
        color: t("fontColor");
      }
    }
    .signature-icon-tooltip {
      position: absolute;
      right: 0px;
      left: 92%;
    }
  }

  .features-container {
    display: flex;
    flex-wrap: wrap;
    .feature-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 12px;
      color: #6a6a6a;
      background: #d9d9d9;
      padding: 1px 8px;
      border-radius: 15px;
      margin-top: 5px;
      white-space: nowrap;

      .beta-tag {
        color: #04c14d;
      }

      &:hover {
        color: white;
        background: #5517c1;

        svg path:not([fill="none"]) {
          fill: white !important;
        }
      }

      &.active {
        color: white;
        background: #5517c1;

        svg path:not([fill="none"]) {
          fill: white !important;
        }
      }

      .spinner-xs {
        height: 0.8rem !important;
        width: 0.8rem !important;
      }
    }
    .feature-container:not(:last-child) {
      margin-right: 16px;
    }

    .back-merge-fields-icon {
      position: absolute;
      cursor: pointer;
      top: 6px;
      left: -35px;
    }
  }
}

.credits-container {
  font-size: 11px;
  @include themed() {
    color: t("iconMidGray");
  }
  .credits-number-container {
    background: #808080;
    padding: 2px 3px;
    border-radius: 2px;
    color: #fff;
    &.blink-orange-background {
      animation: blinker-orange-background 1.2s linear infinite;
    }
    @keyframes blinker-orange-background {
      50% {
        background: #ff5800;
      }
    }

    &.blink-purple-background {
      animation: blinker-purple-background 1.2s linear infinite;
    }
    @keyframes blinker-purple-background {
      50% {
        background: #6c37c9;
      }
    }
  }

  &.blink-orange-color {
    animation: blinker-orange-color 1s linear infinite;
  }
  @keyframes blinker-orange-color {
    50% {
      color: #ff5800;
    }
  }

  &.blink-purple-color {
    animation: blinker-purple-color 1s linear infinite;
  }
  @keyframes blinker-purple-color {
    50% {
      color: #6c37c9;
    }
  }
}

.fsp-navbar--video {
  background-image: url("../img/icons-new/general/camera/camera-black.svg") !important;
}

.signature-text-area {
  border: none;
  background: transparent;

  @include themed() {
    color: t("fontColor");
  }
  &.disabled {
    @include themed() {
      color: t("fontMidGray") !important;
    }

    cursor: pointer;
  }
  @media (max-width: $screen-md) {
    font-size: 12px;
  }
}

.text-area-hint {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 6px;
  font-size: 0.9rem;
  opacity: 0.8;
  @include themed() {
    color: t("fontDarkGray") !important;
  }

  .text-area-hint-tab {
    font-size: 0.7rem;
    padding: 2px;
    border-radius: 4px;
    margin-left: 6px;
    opacity: 1 !important;
    @include themed() {
      border: 1px solid t("cardBorder") !important;
    }
  }
}

.mobile-text-area-hint {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-left: 50px;

  .mobile-message-hint {
    @include themed() {
      border: 1px solid t("primary") !important;
      color: t("primary");
    }
    padding: 3px 10px;
    border-radius: 20px;
    cursor: pointer;
  }
}

.beta-chip {
  color: white !important;
  background-color: #4caf50 !important;
  font-size: 0.6rem !important;
  font-weight: 400 !important;
  height: 18px !important;
  margin-left: 3px !important;

  &.with-padding {
    padding-top: 2px;
  }
}

.schedule-message-modal {
  .modal-dialog {
    width: 400px;
    max-width: 400px;
    border-radius: 5px;
  }
  .modal-content {
    background: #ffffff;
    @include themed() {
      background: t("bg");
    }
  }
}
.schedule-message-container {
  padding: 30px 30px 20px 26px;
  .schedule-message-container-header {
    font-size: 24px;
    margin-left: 6px;
    color: #202020;
    @include themed() {
      color: t("fontColor");
    }
  }
  .schedule-message-container-content {
    padding: 46px 28px 46px 22px;
    .schedule-message-container-content-label {
      margin-bottom: 7px;
      color: #707070;
      font-size: 12px;
    }
    .schedule-message-container-content-time-picker {
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .schedule-message-container-colon {
      @include themed() {
        color: t("fontDarkGray");
      }
    }
  }
  .schedule-message-container-summary {
    background: #f4f4f4;
    border-radius: 11px;
    padding: 20px;
    color: #777777;
    margin-bottom: 27px;
    @include themed() {
      background: t("textAreaWhiteBackground");
      color: t("fontDarkGray");
    }
    .schedule-message-container-summary-title {
      font-size: 12px;
      margin-bottom: 7px;
    }
    .schedule-message-container-summary-description {
      font-size: 15px;
    }
  }
}

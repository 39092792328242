.new-message-modal {
  .modal-dialog {
    max-width: 1100px;
  }

  .modal-content {
    max-height: 530px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    border-radius: 5px;
    border: none;
    padding: 15px 15px 0 15px;
    @media (max-width: 600px) {
      max-height: none;
    }
  }
  .modal-body {
    overflow: hidden;
  }
  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    border-top: none;
    padding-right: 0;
    @media (max-width: 600px) {
      padding-top: 10px;
    }

    .btn {
      min-width: 90px;
    }
  }
  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }
  .search-wrapper-outer {
    display: flex;
  }
  .message-tab {
    border: none;
    // background-color: #fff;
    padding: 0;
    .message-single {
      // background-color: #fff;
      padding: 13px 10px;
      cursor: pointer;
    }
  }
  .people-count {
    color: #122b28;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .people-count-detail {
    // margin-bottom: 30px;
    @media (max-width: 600px) {
      margin-bottom: 10px;
    }

    .single {
      align-items: center;
      color: #b4b4b4;
      display: inline-flex;
      margin-right: 20px;

      .icon {
        margin-left: 8px;
      }
    }
  }

  .selected-people {
    margin-bottom: 20px;
    overflow: auto;
    max-height: 240px;
    @media (max-width: 600px) {
      border-bottom: 1px solid #ddd;
      height: 120px !important;
      overflow-y: scroll !important;
    }

    .single {
      align-items: center;
      background: #f8f8f8;
      border: 1px solid #dbdbdb;
      border-radius: 5px;
      color: #122b28;
      display: inline-flex;
      height: 24px;
      margin-bottom: 6px;
      margin-right: 4px;
      padding: 0 8px;
      box-shadow: none;

      &.group {
        transform: translate(0, 2px);
      }
    }

    .avatar {
      height: 14px;
      min-width: 14px;
      width: 14px;
    }

    .user-icon {
      margin-left: 4px;
    }

    .close-icon {
      color: #122b28;
      font-size: 12px;
      margin-left: 14px;
      opacity: 1;
      margin-top: 1px;
      cursor: pointer;
    }
  }

  .contact-tab-single {
    padding: 0px 10px !important;
    &:hover {
      .thread-item-title-text {
        span {
          color: white !important;
        }
      }
      .view-hidden-result {
        color: #bb86fc !important;
      }
    }

    .view-hidden-result {
      @include themed() {
        color: t("primary");
      }
      cursor: pointer;
      font-weight: 500;
      text-align: center;
    }
  }

  .advance-compose-switcher {
    font-size: 1rem;
  }
}

.skeleton-loading {
  @include themed() {
    background: t("bgRHS");
  }
}

.low-opacity-thread-item {
  cursor: not-allowed !important;
  opacity: 0.7;
}

.duplicate-rhs-arrow-down-icon {
  font-size: 1.5rem !important;
}

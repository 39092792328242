.auto-responder-container {
  max-height: 648px;
  overflow: hidden;

  .default-label {
    @include themed() {
      color: t("label");
    }
  }
}

.auto-responder {
  &__days-selector {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  &__day {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @include themed() {
      border: 2px solid t("cardBorder");
      color: t("sideBarUserProfileDetailsColor");
    }

    &.active {
      @include themed() {
        border: 2px solid t("iconPurple");
        color: t("iconPurple");
      }
    }
  }

  &__time {
    width: 50px !important;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

@media (max-width: 768px) {
  .auto-responder-container {
    max-height: 616px;
  }

  .auto-responder {
    &__days-selector {
      gap: 4px;
    }

    &__day {
      height: 42px;
      width: 42px;
    }
  }
}

.auto-responder-banner-container {
  padding: 2px 10px;
  display: flex;
  align-items: center;

  .auto-responder-banner {
    font-size: 10px;
  }

  svg,
  path,
  g {
    fill: #202020 !important;
  }

  .close-notification-button {
    height: 6px !important;
    width: 6px !important;
  }
}

@import "../../base/global-theme";

.user-detail-tab {
  @include themed() {
    border-left: 1px solid t("lightToneContrast");
  }
  min-height: calc(100% + 10px);

  .sticky-content {
    @include themed() {
      border-bottom: 1px solid t("rhsDividerColor");
    }
    .user-action {
      margin-bottom: 0;
    }
  }

  .contacts-detail {
    padding-top: 0px;
    padding-bottom: 50px;
    @media (max-width: $screen-md) {
      padding-right: 5px;
    }

    &.group {
      position: relative;
      margin-bottom: 10px;
      @include themed() {
        background: t("bgRHS") !important;
      }
      overflow: hidden;
      height: 100%;
      flex: 1;
    }

    &.group .info-single {
      margin-bottom: 25px;
    }

    .title {
      @include themed() {
        color: t("rhsIconColor");
      }

      font-size: 13px;
      text-align: center;
      font-weight: 400;
    }

    .no-margin {
      margin-top: -20px;
      display: flex;
      align-items: center;
    }

    .message-single {
      &:hover {
        background-color: transparent;
      }

      &.contact-tab-single {
        margin-bottom: 0;
      }

      .no-margin {
        margin-top: -40px;
      }
    }

    label {
      margin-bottom: 0 !important;
    }

    .contact-tab-single-lhs:hover {
      @include themed() {
        background: t("primaryHover");
      }
    }

    .contact-tab-single-active {
      background: #eeeeee;
    }

    .contact-tab-single {
      align-items: center;
      display: flex;
      margin-bottom: 0;
      width: calc(100% - 40px);
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;

      &.contact-tab-sub-item {
        width: 100% !important;
      }

      .left-container {
        display: flex;
        flex: 1;
        overflow: hidden;
      }

      &.archived-info-view,
      .archived-info-view {
        opacity: 0.6;
      }

      .avatar {
        height: 36px;
        width: 36px;
        min-width: 36px;
        font-size: 1.06rem;
        color: #c9b4eb !important;
      }

      .group-icon {
        opacity: 1;

        svg {
          min-width: 16px;
        }
      }

      &.branch {
        position: relative;
        padding-left: 0 !important;

        & .rhs-group-item-action.with-collapse-btn {
          top: 2px;
        }

        &:before {
          content: "";
          width: 10px;
          height: 1px;
          background-color: #dbdbdb;
          margin-right: 4px;
          border-left: 1px solid #dbdbdb;
        }

        .group-icon {
          opacity: 0.25;
        }
      }

      .detail {
        display: flex;
        justify-content: space-between;
        overflow: inherit;
        width: calc(100% - 46px);
        margin-left: 10px;
      }

      .detail-single {
        display: flex;
        max-width: 188px;
        width: 100%;
      }

      .info {
        display: flex;

        &.contact .group-icon {
          opacity: 0.25;
        }
        svg.themed-icon {
          @include themed() {
            path:not([fill="none"]) {
              fill: t("iconColor") !important;
            }
          }
        }
      }

      .name {
        @include themed() {
          color: t("iconColor");
        }

        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 400;
        position: relative;
        z-index: 9;
        text-overflow: ellipsis;

        .duplicate {
          opacity: 0.5;
        }
      }

      // .group-icon {
      //   opacity: 1;

      //   svg {
      //     min-width: 16px;
      //   }
      // }

      .hub-icon svg {
        min-width: 13px;

        path:not([fill="none"]) {
          fill: #b4b4b4;
        }
      }
    }

    .collapse-toggler {
      border: none;
      outline: none;
      box-shadow: none;
      background: none;
      position: relative;
      transition: all 0.3s ease-in-out;
      margin-right: 10px;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &[aria-expanded="true"] {
        .arrow-icon {
          transform: rotate(180deg);
        }
      }

      &.collapse-archive {
        right: 0px;
      }
      @include themed() {
        color: t("text");
      }
    }

    .collapse-toggler-lhs {
      right: 0;
      top: 0;
      left: -5px;
    }

    .archived-members-list {
      height: 0px;
      transition: height 0.3s ease-in-out;
      &.show-archived {
        height: auto;
      }
    }
  }

  &.scrolled {
    padding-top: 0px;
    .sticky-content {
      @include themed() {
        background: t("bgRHS");
      }
      position: sticky;
      top: 0;
    }

    .chat-avatar-wrapper {
      flex-direction: row;
      justify-content: flex-start;
      padding: 10px 15px;

      .big-avatar {
        font-size: 18px;
        height: 30px;
        line-height: 30px;
        margin-bottom: 0;
        min-width: 30px;
        width: 30px;
      }

      h5 {
        font-size: 1rem;
        white-space: nowrap;
        align-self: flex-start;
      }

      .details {
        max-width: 240px;
        margin-left: 10px;
      }
    }
  }

  .status-field {
    padding: 20px 20px 0;
    border-bottom: 1px solid #e1e1e1;

    .icon-label {
      width: 100%;
      display: flex;

      .arrow-icon {
        margin-left: auto;
        font-size: 13px;
        color: #707070;
        transition: all 0.3s ease-in-out;
      }
    }

    .btn-recheck {
      float: right;
      margin-right: 0;
      padding-right: 0;
    }

    &.with-collapse {
      .icon-label {
        &[aria-expanded="false"] {
          .arrow-icon {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  .top-action {
    position: absolute;
    top: 5px;
    right: 0;

    .btn-secondary {
      color: #122b28;

      &.dropdown-toggle {
        background: none;
      }

      &:after {
        display: none;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .icon {
      display: inline-block;
      width: 30px;
      text-align: left;

      img {
        width: 17px;
      }
    }

    .dropdown-item {
      color: #122b28;
      font-size: 0.875rem;
      font-weight: 500;

      &:focus {
        background-color: none;
      }

      &:hover {
        background-color: #fff;
      }
    }

    .dropdown-menu {
      border: 1px solid #707070;
      border-radius: 3px;
      @media (max-width: 600px) {
        left: -138px;
      }
    }
  }

  .chat-avatar-wrapper {
    align-items: center;
    @include themed() {
      border-bottom: 1px solid t("rhsDividerColor");
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;

    .duplicate-lhs-menus {
      position: absolute;
      right: 5px;
      top: 10px;
    }

    .text-type-duplicate-info {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 2px;
      margin-top: 15px;
      @include themed() {
        color: t("iconMidGray");
      }
    }

    .details {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;

      .user-fullname {
        display: flex;
        max-width: 225px;

        h5 {
          cursor: default;
          overflow: hidden;
          text-overflow: ellipsis;
          @include themed() {
            color: t("iconColor");
          }
        }
        .edit {
          @include themed() {
            color: t("primary") !important;
          }
          border-radius: 5px;
          font-size: 0.75rem;
          font-weight: 500;
          margin-left: 10px;
          position: relative;
          top: 5px;
        }
      }

      .info-icons {
        display: inline-flex;
        padding: 0;

        svg {
          width: 14px;
          opacity: 1;
          &.themed-icon {
            @include themed() {
              path:not([fill="none"]),
              rect {
                fill: t("rhsIconColor") !important;
              }
            }
          }
        }
      }

      .members-num {
        @include themed() {
          color: t("text");
        }
      }

      .message-tab-group-name {
        list-style: none;

        .list-group-item-text {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          margin: 0px;
          @include themed() {
            color: t("text") !important;
          }
        }

        .save {
          font-size: 14px;
        }
      }

      .contact-num {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
      }

      .phone-icon {
        display: inline-flex;
        margin-left: 8px;

        svg {
          opacity: 0.25;
          min-width: 6px;
          max-height: 12px;
        }
      }
      svg.themed-icon {
        @include themed() {
          path:not([fill="none"]),
          rect {
            fill: t("rhsIconColor") !important;
          }
        }
      }
      .unsubscribe {
        display: inline-flex;
        margin-left: 8px;

        svg {
          max-width: 11px;
        }
      }

      .icon-btn {
        outline: none;
        border: none;
        background: transparent;

        svg {
          opacity: 1;
        }
      }
    }

    .big-avatar {
      font-weight: 400;
      height: 101px;
      line-height: 101px;
      // margin-bottom: 22px;
      margin-right: 0px;
      text-align: center;
      width: 101px;
      color: #c9b4eb;
      font-size: 28px;

      &:hover {
        color: $avatar-text-purple;
      }

      svg {
        min-height: 100%;
        min-width: 100%;
        margin-bottom: 0;
      }
    }
    .middle-avatar {
      width: 40px !important;
      height: 40px !important;
    }
    h5 {
      @include themed() {
        color: t("iconColor");
      }

      // color: #122b28;
      font-size: 1.25rem;
      font-weight: 400;
      margin: 0 0 5px;
    }
  }

  .mobile-chat-avatar-wrapper {
    border-bottom: none !important;
  }

  .contact-side-form-mobile-action-wrapper {
    @include themed() {
      border-top: 1px solid t("rhsDividerColor");
      border-bottom: 1px solid t("rhsDividerColor");
      color: t("fontColor");
    }
    font-size: 14px;
    padding: 5px;
    padding-left: 20px;
    width: 100%;
    margin-bottom: 10px;

    &.favorite-icon {
      padding: 7px;
      padding-left: 20px;
    }

    &.message-icon {
      svg {
        path {
          @include themed() {
            fill: t("fontColor") !important;
          }
        }
      }
    }
  }

  .mobile-call-panel {
    @include themed() {
      border-top: 1px solid t("rhsDividerColor");
    }
  }

  .same-number-label {
    font-size: 13px;
    text-align: center;
  }

  .info-single {
    #side_form_group_status .group-status {
      position: relative;

      .btn-recheck {
        position: absolute;
        right: 0;
        @media (min-width: 530px) and (max-width: 650px) {
          right: -30px;
        }
        @include themed() {
          color: t("primary");
        }
      }
      @media (min-width: 530px) and (max-width: 650px) {
        width: 95%;
      }
    }
  }

  .group-status {
    > span,
    a {
      align-items: center;
      display: inline-flex;
      margin-bottom: 20px;
      margin-right: 10px;
      min-width: 40px;

      span {
        @include themed() {
          color: t("iconColor");
        }
        font-size: 0.94rem;
        margin-left: 5px;
      }
      svg {
        @include themed() {
          path:not([fill="none"]),
          rect:not([fill="none"]) {
            fill: t("rhsIconColor") !important;
          }
        }
      }
    }

    span.light-icons {
      cursor: pointer;
      img {
        max-height: 15px;
        opacity: 0.25;
        width: 14px;
      }
    }

    .btn-recheck {
      margin-right: 0;
      float: right;
    }
  }

  .autoresponse {
    background: transparent;
    resize: none;
  }

  .group-message {
    background: transparent;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding-left: 0;
    padding-right: 0;
    resize: none;
  }

  .autoresponse-action {
    margin-top: 5px;
    text-align: right;

    a {
      color: $primary;
      margin-left: 10px;
    }
  }

  .info-icons {
    padding: 10px 15px;

    a {
      margin: 0 5px;
    }

    .dropdown-toggle {
      color: #122b28;
    }
  }

  .user-action {
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 10px 15px;
    text-align: center;
    margin-bottom: 30px;

    &.search-filter {
      @include themed() {
        background: t("bgRHS") !important;
      }
      height: auto;
      line-height: normal;
      position: relative;
    }

    .user-action-single {
      .unfavorite {
        display: none;
      }

      .hide-detail {
        display: none;
      }

      &.active {
        .favorite {
          display: none;
        }

        .show-detail {
          display: none;
        }

        .unfavorite {
          display: inline-block;
        }

        .hide-detail {
          display: inline-block;
        }
      }

      .icon {
        margin-top: 0;

        &.favorite {
          svg {
            width: 18px;

            path {
              fill: none;
              stroke: #122b28;
            }
          }
        }

        &.unfavorite {
          svg {
            width: 18px;
          }
        }

        svg {
          width: 18px;
          @include themed() {
            path:not([fill="none"]),
            rect:not([fill="none"]) {
              fill: t("rhsIconColor") !important;
            }
          }
          path {
            fill: #122b28;
          }

          rect {
            fill: #122b28;
          }
        }
      }
    }

    .search-wrapper-outer {
      a {
        min-width: 0;
      }
    }

    a {
      svg {
        @include themed() {
          path:not([fill="none"]),
          rect:not([fill="none"]) {
            fill: t("rhsIconColor");
          }
        }
      }
      @include themed() {
        color: t("rhsIconColor");
      }

      display: inline-flex;
      flex-direction: column;
      min-width: 70px;
      padding: 0;

      &:focus {
        box-shadow: none;
      }

      .icon-wrapper {
        flex-grow: 1;
      }

      .label {
        font-size: 12px;
        margin-top: 8px;
        white-space: nowrap;
      }
    }
  }

  .call-panel {
    display: flex;
    // padding-top: 5px;
    // padding-bottom: 5px;
    align-items: center;
    a.user-action-single {
      padding-right: 10px;
      color: #00c186;
    }
    .user-action-single {
      &.themed-icon {
        svg {
          @include themed() {
            path:not([fill="none"]) {
              fill: t("iconMidGray") !important;
            }
          }
        }
      }
    }
  }

  .phone-lhs-field {
    padding: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .phone-number {
      font-size: 16px;
    }
  }

  .panel-container {
    align-items: center;
    flex: 1;
    font-size: 14px;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    display: flex;

    .input-auto-width {
      max-width: 114px;
      text-overflow: ellipsis;
      margin-left: -9px;
      @include themed() {
        color: t("fontColor") !important;
      }
      // width: auto;
      // width: 102px
    }

    select {
      vertical-align: unset;
    }

    .edit {
      @include themed() {
        color: t("primary");
      }
    }

    .select-dropdown-phonefield {
      svg {
        opacity: 0.75;
      }
      .themed-color {
        @include themed() {
          color: t("fontColor");
        }
        svg {
          @include themed() {
            path:not([fill="none"]) {
              fill: t("fontColor") !important;
            }
          }
        }
      }
    }

    .phonefield-lookupBtn {
      border: none;
      background: none;
      outline: none;
      box-shadow: none;
      cursor: pointer;
      svg {
        @include themed() {
          path:not([fill="none"]) {
            fill: t("iconMidGray") !important;
          }
        }
      }
    }

    .select-container {
      position: relative;
      cursor: auto;

      &.active-select {
        cursor: pointer;
        svg {
          opacity: 0.75;
        }
      }

      svg {
        position: absolute;
        right: 5px;
        top: 15px;
        opacity: 0.15;
        margin: 0;
      }
    }

    .phone-field-select {
      text-align-last: end;
      background: none;
      border: none;
      font-size: 13.5px;
      font-weight: 400;
      outline: none;
      appearance: none;
      padding: 10px 14px;
      padding-left: 12px;
      padding-right: 16px;
      cursor: pointer;
      z-index: 1;
      position: relative;

      &:disabled {
        cursor: default;
      }
    }

    svg {
      opacity: 0.25;
      margin-left: 10px;
      margin-right: 5px;
      padding-bottom: 5px;
    }

    a {
      font-size: 12px;
      font-weight: 500;
    }

    button {
      font-size: 12px;
      font-weight: 500;
      color: #5517c1;
    }
  }

  .info-wrapper {
    padding: 0;

    &.contact-side-form-simple {
      padding: 0 20px;
    }

    &.right-panel-group {
      padding-left: 18px;
      padding-right: 18px;
    }

    &.name-fields {
      padding: 0px;
      width: 100%;
      position: relative;

      input {
        min-width: 80%;
        @include themed() {
          color: t("iconColor");
        }
      }
      a.edit,
      span.edit {
        position: absolute;
        right: 0;
        bottom: 0;
        margin-bottom: 10px;
      }
      a.edit {
        @include themed() {
          color: t("primary");
        }
      }
      .MuiInput-formControl::after {
        @include themed() {
          border-bottom-color: t("primary");
        }
      }
    }

    .info-single {
      align-items: flex-end;
      border-bottom: 1px solid #e1e1e1;
      display: flex;

      &.single-field-simple {
        margin-top: 10px;
        .MuiInput-input,
        #side_form_contact_lookup {
          @include themed() {
            color: t("text") !important;
            svg {
              path:not([fill="none"]) {
                fill: t("iconMidGray") !important;
              }
            }
          }
        }
        .MuiInput-underline::after {
          @include themed() {
            border-bottom: 1px solid t("primary");
          }
        }
      }

      .edit {
        @include themed() {
          color: t("primary");
        }
        border-radius: 5px;
        font-size: 0.75rem;
        font-weight: 500;
        margin-left: 10px;
      }

      .info-left {
        label {
          margin: 0;
          font-size: 0.75rem;
          font-weight: 400;
          color: #707070;
          margin-bottom: 4px;
        }

        p {
          color: #122b28;
          font-size: 1rem;
          font-weight: 400;
          margin: 0;
          line-height: 20px;
        }

        .custom-select {
          border: none;
          width: 50%;
          //new icons
          background: url("../../img/icons-new/general/expand/expand-gray.svg")
            right 0.75rem;
          background-repeat: no-repeat;
          background-size: 8px 10px;
          padding: 0 10px;
          height: 20px;
          background-position: 100% 6px;
          padding-left: 0;

          &:focus {
            box-shadow: none;
          }
        }
      }

      label {
        position: relative;
        padding-right: 20px;
        .integration-icon-lhs {
          margin-bottom: 10px;
        }
      }

      &.with-collapse {
        border-bottom: 0;
        display: block;
        padding-bottom: 0;

        label {
          align-items: center;
          @include themed() {
            color: t("fontMidGray");
          }

          display: flex;
          font-size: 0.875rem;
          font-weight: normal;
          font-family: "Roboto", "Helvetica Neue", sans-serif;
          justify-content: space-between;
          margin: 0 0 4px;
          width: 100%;
          label {
            padding-right: 0;
          }

          .menus-actions {
            position: absolute;
            right: 10px;
          }

          &[aria-expanded="true"] {
            .arrow-icon {
              transform: rotate(-180deg);
            }
          }

          .arrow-icon {
            font-size: 13px;
            transition: all 0.3s ease-in-out;
          }
        }

        .text-grey {
          color: #8f8f8f;
        }

        p {
          margin: 0;
        }

        .info-left p {
          font-size: 0.9rem;
        }

        .contacts-collapse {
          padding-bottom: 0;
          position: relative;

          &:before {
            content: "";
            background-color: #dbdbdb;
            width: 1px;
            position: absolute;
            top: -4px;
            bottom: 20px;
          }

          &.show {
            border-bottom: 0;
          }
        }

        .select2-container {
          width: 65px !important;
        }

        .collapse {
          width: 100%;
          padding-bottom: 5px;

          &.show {
            border-bottom: 1px solid #e1e1e1;

            .select2-container {
              width: 65px !important;
            }
          }

          &.collapsing {
            overflow: hidden;
            white-space: nowrap;

            .select2-container {
              width: 65px !important;
            }
          }

          .contacts-collapse {
            border-left: 1px solid #dbdbdb;
            padding-bottom: 0;

            &.show {
              border-bottom: 0;
            }
          }
        }

        .collapse-no-border {
          width: 100%;
          padding-bottom: 5px;
          font-size: 1rem;

          &.show {
            .select2-container {
              width: 65px !important;
            }
          }

          &.collapsing {
            overflow: hidden;
            white-space: nowrap;

            .select2-container {
              width: 65px !important;
            }
          }
        }

        &.upload-image {
          .show {
            border-bottom: 1px solid #e1e1e1 !important;
            padding-bottom: 0;
          }

          .custom-file-label {
            background: transparent;
            border: none;
            padding-left: 0;
            padding-bottom: 0;
            color: #122b28;
            font-size: 14px;
            justify-content: flex-start;

            &:focus {
              border: none;
              box-shadow: none;
            }

            .image-name {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }

          .custom-file-label:after {
            content: "";
            background: transparent;
          }
        }

        .message-single {
          padding: 0;
          margin-left: -7px;
        }
      }
    }
  }
  .info-text {
    font-size: 16px;
    text-align: center;
    border-bottom: 2px solid #e1e1e1;
    padding: 10px 0;
    p {
      margin: auto;
    }
  }
  .info-group {
    margin: 20px;
    p {
      margin: auto;
    }
    .info-item {
      margin: 20px 0;
      .item-title {
        font-size: 14px;
        color: #a6a6a6;
      }
      .item-text {
        font-size: 16px;
      }
    }
  }
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-bottom: 1rem;
  overflow: hidden;
  margin-top: -25px;
  padding-top: 25px;

  .edit {
    position: absolute;
    right: 0;
    top: 0px;
    visibility: hidden;
  }

  &.chips-container-active {
    opacity: 1;
    .edit {
      visibility: visible;
    }

    .contact-side-chip {
      opacity: 1;
    }
  }

  &.level-1-expansion {
    max-height: 97px;
  }

  &.level-2-expansion {
    max-height: 200px;
  }

  .expand-border {
    width: 100%;
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    @include themed() {
      background: t("bgRHS");
    }
    padding-bottom: 5px;
    padding-top: 8px;

    .expansion-line {
      height: 1px;
      background: #c9cecd;
    }
  }
}

.contact-side-chip {
  max-width: 139px;
  align-items: center;
  @include themed() {
    background: t("bgTransparent");
  }
  @include themed() {
    border: 1px solid t("contactChipBorder");
  }
  border-radius: 3px;
  @include themed() {
    color: t("fontDarkGray");
  }
  display: flex;
  font-size: 14px;
  margin-top: 5px;
  margin-right: 5px;
  padding: 2px 8px;
  position: relative;
  overflow: hidden;
  height: 22px;

  &.contact-side-chip-disabled {
    @include themed() {
      border: 1px solid t("contactChipBorder");
    }
    @include themed() {
      color: t("fontDarkGray");
    }
    &:hover {
      cursor: default;
      @include themed() {
        color: t("fontDarkGray");
      }
      @include themed() {
        border: 1px solid t("contactChipBorder");
      }
    }

    .chip-text-title {
      color: #808080;
    }
  }

  &:hover {
    @include themed() {
      color: t("primary");
    }
    cursor: pointer;
    @include themed() {
      border-color: t("primary");
    }
  }

  .chip-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 16px;
    font-size: 14px;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    span:first-child {
      margin-right: 5px;
    }
  }

  .close-btn {
    align-content: center;
    display: flex;
    align-items: center;
    font-size: 10px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    color: #ffffff;
    position: absolute;
    right: 0;
    cursor: pointer;
    margin-right: 6px;
    justify-content: center;
    background-color: #808080;
    margin-bottom: 1px;

    &.show {
      @include themed() {
        background: t("primary");
      }
      color: #fff;
      display: flex;
    }
  }
  .close-btn-text {
    line-height: 1;
    font-weight: 600;
  }
}

.chip-text-title {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  @include themed() {
    color: t("primary");
  }
}

.icon-btn {
  outline: none;
  border: none;
  background: transparent;
  box-shadow: none;

  svg {
    opacity: 1;
    @include themed() {
      path:not([fill="none"]) {
        fill: t("rhsIconColor") !important;
      }
    }
  }

  &:focus {
    outline: none;
  }
}
.icon-btn:hover {
  svg {
    opacity: 1;
    @include themed() {
      path:not([fill="none"]) {
        fill: t("primary") !important;
      }
    }
  }
}

.top-panel-actions {
  @include themed() {
    border-left: 1px solid t("lightToneContrast");
  }
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0px 20px;
  transition: padding 0.3s ease-in-out;

  .icons-container {
    display: flex;
    align-items: center;
  }

  button:not([id="archive"]) {
    outline: none;
    border: none;
  }

  button[id="archive-confirm-button"] {
    background: $primary;
  }

  button[id="archive-confirm-button"]:hover {
    background: #46139f;
  }

  &.minimized-view {
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
  .themed-color {
    path:not([fill="none"]) {
      @include themed() {
        fill: t("rhsIconColor");
      }
    }
  }
}

.user-detail-group {
  margin-left: 30px;
  @media (max-width: $screen-md) {
    margin-left: 10px;
  }
  .info-single {
    #side_form_group_status_header .group-status {
      position: relative;

      .btn-recheck {
        position: absolute;
        right: 0;
      }
    }
    .group-status {
      span,
      a {
        align-items: center;
        display: inline-flex;
        margin-bottom: 5px;
        margin-right: 6px;
        min-width: 18px;
        span {
          color: #122b28;
          font-size: 0.94rem;
          margin-left: 5px;
          margin-top: 6px;
        }
      }

      .light-icons {
        img {
          max-height: 15px;
          opacity: 0.25;
          width: 14px;
        }

        &.active {
          img {
            opacity: 0.8;
          }
          .text {
            color: #5517c1;
          }
        }
      }
      @media (max-width: $screen-md) {
        flex-wrap: wrap;
      }
    }
    .flex {
      display: flex;
    }
    .buttons-container {
      color: #5517c1;
      display: flex;
      align-items: center;
      font-size: 12px;
    }
  }
  .status-field {
    padding: 0 20px 0 0;
    display: flex;
    .icon-label {
      width: 100%;
      display: flex;
      margin-right: 25px;
      font-size: 12px;
      .arrow-icon {
        margin-left: auto;
        font-size: 10px;
        // transition: all 0.3s ease-in-out;
      }
    }
    .icon-hover:hover {
      cursor: pointer;
    }
    .btn-recheck {
      float: right;
      margin-right: 0;
      padding-right: 0;
    }

    .icon-label-group {
      margin-top: 7px;
      .arrow-icon {
        font-size: 13px;
      }
    }
    @media (max-width: $screen-md) {
      padding: 0px;
      flex-wrap: wrap;
      width: 100vw;
    }
  }
}

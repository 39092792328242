@import "../base/global-theme";

.main-sidebar-user-profile {
  height: 90px;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  gap: 6px;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  &.minimized {
    height: auto;
    padding: 0px;
    background: none !important;
    border: none !important;
    justify-content: center;
    gap: 0px;
  }

  @include themed() {
    background: t("sideBarUserProfileBackground");
    border: 1px solid t("sideBarDividerColor");
  }
  .user-dropright-avatar {
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .user-img-container {
    display: inline-flex;
    align-items: center;
    height: 22px;
    max-width: 15px;
    .user-img {
      height: 15px;
      width: 15px;
      min-width: 15px;
      max-width: 15px;
      line-height: 15px;
      img {
        @extend .user-img;
      }
    }
  }

  .sidebar-avatar-wrapper {
    background: transparent !important;
  }

  .user-details {
    text-transform: capitalize;
    margin-left: 6px;
    opacity: 1;
    transition: opacity 2s ease-out;
    height: auto;
    width: auto;

    &.minimized {
      opacity: 0;
      height: 0;
      width: 0;
      overflow: hidden;
    }
    .full-name {
      cursor: pointer;
      height: 22px;
      display: block;
      padding-top: 1px;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 112px;
      &.minimized {
        width: auto;
      }
      @include themed() {
        color: t("sideBarUserProfileNameColor");
      }
    }
    .other-details {
      font-size: 12px;
      width: 112px;
      &.minimized {
        width: auto;
      }
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @include themed() {
        color: t("sideBarUserProfileDetailsColor");
      }
    }
  }
}

.main-sidebar-settings-dropright {
  display: block;
  .expand-profile-icon {
    cursor: pointer;
    margin-left: 10px;
    &.minimized {
      margin-left: 0px;
    }
    svg {
      transform: rotate(-90deg);
      height: 9px;
      width: 9px;
      @include themed() {
        path {
          fill: t("sideBarUserProfileIconColor");
        }
      }
    }
  }
  a {
    &:hover {
      svg {
        path {
          fill: none;
        }
      }
      svg#intercom {
        path#rectangle-shape,
        path#path-shape {
          fill: #fff;
        }
        path#smile {
          fill: #593ac1;
        }
      }
    }

    svg {
      path {
        fill: none;
      }
    }
    svg#intercom {
      path#rectangle-shape,
      path#path-shape {
        fill: #fff;
      }
      path#smile {
        fill: #593ac1;
      }
    }
  }

  .button-setting {
    padding: 0px;
    background-color: transparent;
    border: none;
    height: 42px;

    &:focus {
      box-shadow: none !important;
    }

    &:not(:disabled):not(.disabled):active {
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
  }

  .sidebar-expanded-btn-setting {
    label {
      font-size: 14px;
      cursor: pointer;
      @include themed() {
        color: t("menuFontColor");
      }
    }
  }

  .sidebar-dropdown-menu {
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
  }

  .dropdown-menu {
    position: relative;
    &::before {
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid #ffffff;
      background-color: transparent;

      position: absolute;
      bottom: 0px;
      left: -5px;
      content: "";
      z-index: 1;
    }
    @include themed() {
      background: t("bgRHS");
    }
  }

  &.minimized {
    .dropdown-menu.show {
      left: 40px !important;
      bottom: 24px !important;
    }
  }

  .dropdown-menu.show {
    left: 176px !important;
    bottom: 63px !important;
    top: auto !important;
    transform: none !important;
    border-radius: 4px;
    padding: 15px 0px;
    width: 300px;
    position: relative;

    .dropdown-item {
      @include themed() {
        background: t("bgRHS");
      }
    }

    .account-info {
      padding: 0px 20px;
      &.company {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      .number-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include themed() {
          border-bottom: 1px solid t("tableDivider");
        }
        padding: 15px 5px;
        .filter-number {
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          @include themed() {
            color: t("userProfileMenuItemColor");
          }
        }

        .number {
          @include themed() {
            color: t("primary") !important;
          }
          font-weight: 500;
          margin-left: 8px;
        }

        .phone-number-wrapper {
          border-radius: 5px;
          padding: 5px;
          font-size: 14px;
          @include themed() {
            border: 1px solid t("tableDivider");
            color: t("userProfileMenuItemColor");
          }
        }
      }

      .name {
        font-size: 14px;
        font-weight: 500;
        @include themed() {
          color: t("userProfileFullNameColor");
        }
      }

      .edit {
        font-size: 14px;
        font-weight: 500;
        @include themed() {
          color: t("primary");
        }
      }

      .post {
        font-size: 12px;
        @include themed() {
          color: t("userProfileRoleColor");
        }
      }
      .company-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 5px;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 14px;
        @include themed() {
          border-bottom: 1px solid t("tableDivider");
        }
        @include themed() {
          color: t("userProfileMenuItemColor");
          path:not([fill="none"]) {
            fill: t("userProfileMenuItemColor");
          }
        }
      }

      .user-credits {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 15px 5px;
        font-size: 14px;
        font-weight: 500;
        @include themed() {
          border-bottom: 1px solid t("tableDivider");
          color: t("userProfileMenuItemColor");
          path:not([fill="none"]) {
            fill: t("userProfileMenuItemColor");
          }
        }
        .billing-analytics-container {
          margin-top: 5px;
          margin-left: 25px;
        }
      }

      .name-title {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 15px 5px;
        @include themed() {
          border-bottom: 1px solid t("tableDivider");
        }
      }

      .user-avatar {
        background-color: #666666;
        height: 25px;
        width: 25px;
        min-width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }
      &:focus {
        outline: none;
      }
    }
    .menuitem-wrapper {
      padding: 0px 20px;
      .menuitem {
        @include themed() {
          border-bottom: 1px solid t("tableDivider");
          color: t("userProfileMenuItemColor");
        }
        padding: 15px 5px;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        text-transform: capitalize;

        a {
          @include themed() {
            color: t("userProfileMenuItemColor");
            path:not([fill="none"]) {
              fill: t("userProfileMenuItemColor");
            }
          }
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .company-tab {
          @include themed() {
            color: t("userProfileMenuItemColor");
          }
          .a {
            fill: #666 !important;
          }
          .b {
            fill: none !important;
          }
          .c {
            fill: #c9b4eb !important;
          }
        }
      }

      .admin-user-menus-wrapper {
        @include themed() {
          color: t("userProfileMenuItemColor");
        }
        padding: 8px 5px;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
      }

      &.admin-user-menus-container {
        padding: 3px 20px;
      }

      .social-menu-container {
        @include themed() {
          border-top: 1px solid t("tableDivider");
        }
      }

      &.last-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px !important;
      }
      svg {
        path:not([fill="none"]) {
          @include themed() {
            fill: t("userProfileMenuItemColor") !important;
          }
        }
      }

      &:focus {
        outline: none;
      }
    }
  }

  .btn-intercom-launcher {
    @include themed() {
      color: t("userProfileMenuItemColor");
    }
  }
  .btn-outline-primary {
    @include themed() {
      color: t("primary");
      border-color: t("primary");
    }
    &:hover {
      @include themed() {
        color: t("userProfileMenuItemColor");
        background-color: t("primary");
      }
    }
  }
  .arrow-icon {
    @include themed() {
      color: t("fontDarkGray");
    }
    font-size: 12px;
  }
}

@import "../base/global-theme";

.chat-locked-button {
  align-items: center;
  @include themed() {
    background: rgba(t("fontDarkGray"), $alpha: 0.8);
    @include themed() {
      color: t("bg");
    }
  }
  bottom: 0;
  display: flex;
  font-size: 16px;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  &:hover,
  &:active,
  &:focus {
    @include themed() {
      color: t("bg");
    }
  }

  svg {
    height: 17px;
    margin-right: 10px;
    width: 17px;
    @include themed() {
      path:not([fill="none"]) {
        fill: t("bg") !important;
      }
    }
  }
}
.alert + .chat-locked-button {
  top: 39px;
}
.locked-info-modal {
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    background: $primary;
    border: none;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);

    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2;
    min-width: 285px;
    padding: 40px 20px !important;
    text-align: center;
    width: auto;
  }
  .modal-body {
    padding: 1rem !important;
  }
  .icon {
    display: inline-block;
    margin-bottom: 15px;
    svg {
      height: 28px;
      width: 28px;
    }
  }
  p {
    margin: 0;
  }
  .locked-info-modal-container {
    color: #000;
  }
  &.medium {
    .modal-content {
      background-color: #ffe577;
      color: #000;
    }
  }
  &.soft {
    .modal-content {
      background-color: #ffff;
      color: #000;
    }
  }
  &.no-credits {
    .modal-header {
      margin-bottom: 0px !important;
      .close-icon {
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
    .modal-content {
      @include themed() {
        background-color: t("bgRHS");
      }
      padding-top: 25px !important;
      padding-bottom: 25px !important;
      min-width: max-content;
      @media (max-width: $screen-xs) {
        min-width: auto;
      }
    }
    p {
      margin: 0;
      text-align: start;
      line-height: 26px;
      @include themed() {
        color: t("fontColor") !important;
      }
    }
    .no-credits-buttons {
      display: flex;
      justify-content: space-around;
      margin-top: 42px;
      .btn-outline-primary:hover {
        background-color: white;
        color: $primary;
      }
      @media (max-width: $screen-xs) {
        flex-direction: column;
        button:nth-of-type(2) {
          margin-top: 40px;
        }
      }
    }
  }
  .modal-details {
    color: white !important;
    font-weight: bold !important;
  }
}

.new-call-with-number {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 380px;
    }
  }

  .modal-content {
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    min-height: 450px;
    padding: 20px;
  }

  .modal-header {
    border-bottom: none;
    position: relative;

    .close-icon {
      position: absolute;
      right: -8px;
      top: -15px;
    }
  }

  .modal-footer {
    border-top: none;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }

  .show-contact {
    align-items: center;
    display: flex;

    .name {
      color: #122b28;
      font-size: 16px;
      margin-bottom: 0;
      margin-left: 10px;
    }

    .search {
      margin-bottom: 8px;
      margin-left: auto;
    }
  }

  .entered-number {
    padding: 30px;

    span {
      color: #122b28;
      font-size: 24px;
    }
  }

  .dial-pad {
    padding: 0 20px 20px;

    .single {
      align-items: center;
      border: 1px solid #122b28;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      height: 55px;
      justify-content: center;
      margin-bottom: 10px;
      width: 55px;

      .number {
        color: #122b28;
        font-size: 30px;
        font-weight: 300;
        line-height: 1;
        margin: -2px 0 2px;
      }

      .alphabet {
        color: #122b28;
        font-size: 9px;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
      }
    }

    .dial {
      align-items: center;
      background-color: #36c02b;
      border-radius: 50%;
      display: flex;
      height: 55px;
      justify-content: center;
      margin-top: 8px;
      width: 55px;

      i {
        color: #fff;
        font-size: 35px;
      }
    }

    .clear {
      align-items: center;
      display: flex;
      height: 55px;
      justify-content: center;
      margin-top: 5px;
      width: 55px;
    }
  }
}

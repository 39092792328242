.sidebar-icon {
  @include themed() {
    color: t("menuFontColor");
    svg path:not([fill="none"]) {
      fill: t("menuFontColor");
    }
  }
}

.sidebar-notifications-settings {
  margin-left: 23px;
  &.minimized {
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sidebar-notifications-settings-icon {
    @include themed() {
      svg path,
      rect {
        stroke: t("menuFontColor") !important;
        fill: t("menuFontColor") !important;
      }
    }
    @extend .sidebar-icon;
    cursor: pointer;
  }
}

.sidebar-notifications-settings-popover {
  .popover {
    left: 180px !important;
    position: relative;
    z-index: 1500;
  }
  &.minimized {
    .popover {
      left: 250px !important;
    }
  }
}
.sidebar-notifications-settings-popover-text {
  font-size: 14px;
  .sidebar-notifications-settings-popover-link-text {
    color: #6c37c9;
    cursor: pointer;
  }
}
.sidebar-notifications-settings-popover-body {
  display: inline-block;
}

/* Bonus Notification Popover & icon */
.sidebar-notifications-bonus-text {
  color: #64c3e0;
  font-weight: bold;
  font-size: 11px;
  margin-left: 18px;
}
.gift-notification-bullet {
  right: 27px !important;
  top: 2px !important;
}
.sidebar-expanded-notification-bullet {
  left: 8%;
}

.sidebar-expanded-message-icon-bullet {
  left: 0%;
  margin-left: 25px !important;
}

.sidebar-notifications-bonus-icon {
  margin-left: 25px;
  margin-bottom: 16px;
  .sidebar-expanded-list-image {
    height: 15px;
    width: 15px;
  }
  &.minimized {
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .expand-integrations-icon {
    cursor: pointer;
    transform: rotate(-90deg);
    height: 9px;
    width: 9px;
    position: absolute;
    right: 35px;
    @include themed() {
      path {
        fill: t("sideBarUserProfileIconColor");
      }
    }
    &.minimized {
      right: 8px;
    }
  }
  @extend .sidebar-icon;
  // @media (max-height: 565px) {
  //   margin-bottom: 12px;
  // }
}
.bonus-notification-popover {
  .bs-popover-right {
    margin-left: 0px;
    max-width: 470px;
    max-height: 230px;
    border-radius: 20px;
    border: 5px solid #00dee6;
    .arrow {
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: scale(2);
      left: -16px;
      &::before {
        border-width: 0.6rem 0.6rem 0.6rem 0;
        border-right-color: #00dee6;
      }
      &::after {
        left: 3px;
      }
    }
    .close-icon {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }
  .bonus-notification-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 40px;
    height: 100%;
    .countdown-timer {
      display: block;
      color: #a6a6a6;
      font-size: 24px;
      font-weight: bold;
    }
    td {
      text-align: center;
    }
    .time-elements {
      font-size: 10px;
      font-weight: normal;
    }
    .bonus-text {
      padding: 18px 30px;
      text-align: center;
    }
  }
}
#bonus-notification {
  .a,
  .b {
    fill: none;
    stroke: #64c3e0;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .a {
    stroke-width: 0.8px;
    font-size: 9px;
    letter-spacing: 0.15em;
  }
  .b {
    stroke-width: 2.3px;
  }
}

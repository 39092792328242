.top-bars {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  z-index: 101000;
}

.modified-tab-content {
  margin-top: 40px;
  @include themed() {
    background: t("bg");
  }
}

.alert.top-alert {
  &.info {
    background: #64c3e0;
    color: white;

    a {
      font-weight: bold;
      cursor: pointer;
      color: white;
    }
  }

  &.warning {
    background: #ffff57;
    color: #333333;
    svg {
      path:not([fill="none"]),
      rect:not([fill="none"]) {
        fill: #333333 !important;
      }
    }
    a {
      font-weight: bold;
      cursor: pointer;
      color: #333333;
    }
    .close {
      i {
        color: black;
      }
    }
  }

  &.danger {
    background: $secondary;
    color: #fff;
  }

  &.inactive {
    background: #000;
    color: #fff;
  }

  p {
    margin-bottom: 0;

    a {
      text-decoration: underline;
    }
  }

  .close {
    opacity: 1;
    padding: 0;
    margin-left: 10px;

    i {
      color: white;
      font-size: 16px;
    }
  }

  .close-icon {
    cursor: pointer;
    width: 20px;
    margin-top: -3px;
  }

  .alert-text {
    width: 95%;
  }
}

.top-alert + .header {
  top: 40px;
}

.top-alert + .header + .sidebar {
  top: 100px;
}

.top-alert + .header + .sidebar + .page-wrapper {
  padding-top: 100px;
}

// .top-alert + .top-alert {
// }

// chat box alert

.chat-box {
  .top-alert {
    padding: 5px 10px;
    position: relative;
  }
}

// hybrid
$text--hybrid: #807f82;
$bg-rhs--hybrid: #f8f8f8;
$bg--hybrid: white;
$bg--hybrid-white-background: #f4f4f4;
$primary-color--hybrid: #5517c1;
$font-color--hybrid: #202020;
$font-dark-gray--hybrid: #808080;
$font-mid-gray--hybrid: #a6a6a6;
$font-light-gray--hybrid: #d9d9d9;
$icon-color--hybrid: #122b28;
$light-tone-contrast--hybrid: #e1e1e1;
$rhs-icon-color--hybrid: #6c757d;
$icon-mid-gray--hybrid: #565656;
$font-normal-gray--hybrid: #b4b4b4;
$left-sidebar-menu--hybrid: #202020;
$left-menu--hybrid: #272727;
$left-menu-text--hybrid: #d9d9d9;
$filter-background-hover--hybrid: #3e374a;
$avatar-background--hybrid: #c9b4eb;

$filter-background--hybrid: #ededed;
$filter-text--hybrid: #666666;
$search-input-background--hybrid: #fff;
$search-input-border--hybrid: #ddd;
$search-clear--hybrid: #6c757d;
$icon-purple--hybrid: #6c37c9;

$thread-list-background--hybrid: #2a2a2a;

$thread-action--hybrid: #bb86fc;
$skeleton-body--hybrid: #262626;
$skeleton-body-gradient--hybrid: #3c3c3c;
$incoming-message--hybrid: #f2f2f2;
$sent-message--hybrid: #ebebff;
$inverse-font-color--hybrid: #bb86fc;

$menu-font-color--hybrid: #c2c2c2;
$menu-inverse-font--hybrid: white;

$side-bar-text-highlight--hybrid: #f8f8f8;
$link-text--hybrid: #c9b4eb;

$primary-action-button-text--hybrid: #ffffff;
$primary-action-button-bg--hybrid: #664f8e;
$primary-action-button-disabled-text--hybrid: #ffffff;
$primary-action-button-disabled-bg--hybrid: #c9b4eb;

$primary-action-button-text-sidebar--hybrid: #202020;
$primary-action-button-bg-sidebar--hybrid: #bb86fc;
$primary-action-button-disabled-text-sidebar--hybrid: #202020;
$primary-action-button-disabled-bg-sidebar--hybrid: #49385f;

$default-action-button-text--hybrid: #333333;
$default-action-button-bg--hybrid: #dddddd;
$default-action-button-disabled-text--hybrid: #999999;
$default-action-button-disabled-bg--hybrid: #eeeeee;

$default-action-button-text-sidebar--hybrid: #dddddd;
$default-action-button-bg-sidebar--hybrid: #4d4d4d;
$default-action-button-disabled-text-sidebar--hybrid: #7d7d7d;
$default-action-button-disabled-bg-sidebar--hybrid: #373737;

$sidebar-color--hybrid: #b8babe;
$sidebar-color-hover--hybrid: #f8f8f8;
$sidebar-color-selected--hybrid: #f8f8f8;
$sidebar-background--dark: none;
$sidebar-background-hover--hybrid: #3a3b3c;
$sidebar-background-selected--hybrid: #3e374a;
$sidebar-divider-color--hybrid: #3a3b3c;

$sidebar-user-profile-name-color--hybrid: #b8babe;
$sidebar-user-profile-details-color--hybrid: #b8babe;
$sidebar-user-profile-background--hybrid: #3a3b3c;
$sidebar-user-profile-icon-color--hybrid: #b8babe;

$user-profile-full-name-color--hybrid: #666666;
$user-profile-role-color--hybrid: #808080;
$user-profile-menu-item-color--hybrid: #565656;

$thread-items-hover--hybrid: #6c37c9;
$additional-red-text--hybrid: #eb6033;

$new-contact-icon--hybrid: #c9b4eb;

$modal-menu-bg--hybrid: #e1d4f5;

// dark
$text--dark: #e1e1e1;
$bg--dark: #202020;
$bg-rhs--dark: #272727;
$primary-color--dark: #bb86fc;
$font-color--dark: #e1e1e1;
$font-normal-gray--dark: #707070;
$icon-color--dark: #e1e1e1;
$light-tone-contrast--dark: #1d1d1d;
$rhs-icon-color--dark: #afafaf;
$bg-transparent--dark: transparent;
$bg-LHS--dark: #2a2a2a;
$primary-color-hover--dark: #3e374a;
$left-sidebar-menu--dark: #202020;
$left-menu--dark: #272727;
$left-menu-text--dark: #d9d9d9;
$filter-background-hover--dark: #3e374a;
$avatar-background--dark: #c9b4eb;

$filter-background--dark: #2a2a2a;
$filter-text--dark: #afafaf;
$search-input-background--dark: #333335;
$search-input-border--dark: #707070;
$search-clear--dark: #e1e1e1;
$icon-purple--dark: #bb86fc;

$thread-list-background--dark: #2a2a2a;
$thread-action--dark: #bb86fc;
$skeleton-body--dark: #262626;
$skeleton-body-gradient--dark: #3c3c3c;
$incoming-message--dark: #3e4042;
$incoming-message-text--dark: #b8babe;
$sent-message--dark: #4f239d;
$text-area--dark: #3a3b3c;
$inverse-font-color--dark: #bb86fc;

$menu-font-color--dark: #c2c2c2;
$menu-inverse-font--dark: white;

$side-bar-text-highlight--dark: #f8f8f8;
$link-text--dark: #c9b4eb;

$primary-action-button-text--dark: #202020;
$primary-action-button-bg--dark: #bb86fc;
$primary-action-button-disabled-text--dark: #202020;
$primary-action-button-disabled-bg--dark: #49385f;

$default-action-button-text--dark: #dddddd;
$default-action-button-bg--dark: #4d4d4d;
$default-action-button-disabled-text--dark: #7d7d7d;
$default-action-button-disabled-bg--dark: #373737;

$sidebar-color--dark: #b8babe;
$sidebar-color-hover--dark: #f8f8f8;
$sidebar-color-selected--dark: #f8f8f8;
$sidebar-background--dark: none;
$sidebar-background-hover--dark: #3a3b3c;
$sidebar-background-selected--dark: #3e374a;
$sidebar-divider-color--dark: #3a3b3c;

$sidebar-user-profile-name-color--dark: #b8babe;
$sidebar-user-profile-details-color--dark: #b8babe;
$sidebar-user-profile-background--dark: #3a3b3c;
$sidebar-user-profile-icon-color--dark: #b8babe;

$user-profile-full-name-color--dark: #b8babe;
$user-profile-role-color--dark: #b8babe;
$user-profile-menu-item-color--dark: #b8babe;

$thread-items-hover--dark: #6c37c9;
$additional-red-text--dark: #eb6033;
$new-contact-icon--dark: #c9b4eb;

$modal-menu-bg--dark: #3e374a;

//light
$text--light: #807f82;
$bg-rhs--light: #f8f8f8;
$bg--light: white;
$primary-color--light: #5517c1;
$font-color--light: #202020;
$font-dark-gray--light: #808080;
$font-mid-gray--light: #a6a6a6;
$font-light-gray--light: #d9d9d9;
$icon-color--light: #122b28;
$light-tone-contrast--light: #e1e1e1;
$rhs-icon-color--light: #6c757d;
$icon-mid-gray--light: #565656;
$font-normal-gray--light: #b4b4b4;
$left-sidebar-menu--light: #f8f8f8;
$left-menu--light: #f8f8f8;
$left-menu-text--light: #333335;
$filter-background-hover--light: #c9b4eb;
$thread-list-background--light: #f8f8f8;
$thread-action--light: #664f8e;
$skeleton-body--light: #f5f5f5;
$skeleton-body-gradient--light: #808080;
$incoming-message--light: #f2f2f2;
$sent-message--light: #ebebff;
$avatar-background--light: #3e374a;
$inverse-font-color--light: #5517c1;
$menu-font-color--light: #664f8e;
$menu-inverse-font--light: #333335;

$side-bar-text-highlight--light: #202020;
$link-text--light: #5517c1;

$primary-action-button-text--light: #ffffff;
$primary-action-button-bg--light: #664f8e;
$primary-action-button-disabled-text--light: #ffffff;
$primary-action-button-disabled-bg--light: #c9b4eb;
$default-action-button-text--light: #333333;
$default-action-button-bg--light: #dddddd;
$default-action-button-disabled-text--light: #999999;
$default-action-button-disabled-bg--light: #eeeeee;

$sidebar-color--light: #666666;
$sidebar-color-hover--light: #202020;
$sidebar-color-selected--light: #202020;
$sidebar-background--light: none;
$sidebar-background-hover--light: #e1e1e1;
$sidebar-background-selected--light: #e1d4f5;
$sidebar-divider-color--light: #e1e1e1;

$sidebar-user-profile-name-color--light: #666666;
$sidebar-user-profile-details-color--light: #8e8e8e;
$sidebar-user-profile-background--light: #ffffff;
$sidebar-user-profile-icon-color--light: #8e8e8e;

$user-profile-full-name-color--light: #666666;
$user-profile-role-color--light: #808080;
$user-profile-menu-item-color--light: #565656;

$thread-items-hover--light: #6c37c9;
$additional-red-text--light: #eb6033;
$new-contact-icon--light: #6c37c9;

$modal-menu-bg--light: #e1d4f5;

$themes: (
  hybrid: (
    bg: $bg--hybrid,
    bgRHS: $bg-rhs--hybrid,
    bgLHS: $bg--hybrid,
    bgTransparent: $bg--hybrid,
    text: $text--hybrid,
    primary: $primary-color--hybrid,
    fontColor: $font-color--hybrid,
    fontMidGray: $font-mid-gray--hybrid,
    fontDarkGray: $font-dark-gray--hybrid,
    iconColor: $icon-color--hybrid,
    iconMidGray: $icon-mid-gray--hybrid,
    lightToneContrast: $light-tone-contrast--hybrid,
    rhsIconColor: $rhs-icon-color--hybrid,
    rhsDividerColor: $light-tone-contrast--hybrid,
    contactChipBorder: $font-light-gray--hybrid,
    fontNormalGray: $font-normal-gray--hybrid,
    cardBorder: $font-light-gray--hybrid,
    tableHeader: $rhs-icon-color--hybrid,
    label: $font-dark-gray--hybrid,
    primaryHover: $bg-rhs--hybrid,
    tableDivider: $light-tone-contrast--hybrid,
    leftSideMenu: $left-sidebar-menu--hybrid,
    leftMenu: $left-menu--hybrid,
    leftMenuText: $left-menu-text--hybrid,
    filterBackgroundHover: $filter-background-hover--hybrid,
    filterBackground: $filter-background--hybrid,
    filterText: $filter-text--hybrid,
    searchInputBackground: $search-input-background--hybrid,
    searchInputBorder: $search-input-border--hybrid,
    searchClear: $search-clear--hybrid,
    iconPurple: $icon-purple--hybrid,
    threadListBackground: $thread-list-background--hybrid,
    threadMessage: $left-menu-text--hybrid,
    threadCheckbox: $bg--hybrid,
    threadActionIcon: $thread-action--hybrid,
    skeletonBody: $skeleton-body--hybrid,
    skeletonBodyGradient: $skeleton-body-gradient--hybrid,
    settingsMenuText: $rhs-icon-color--hybrid,
    nothingFoundText: $left-menu-text--hybrid,
    selectedThreadItem: $bg--hybrid,
    incomingMessage: $incoming-message--hybrid,
    incomingMessageText: $font-color--hybrid,
    sentMessage: $sent-message--hybrid,
    textArea: $bg--hybrid,
    avatarBackground: $avatar-background--hybrid,
    inverseFontColor: $inverse-font-color--hybrid,
    menuFontColor: $menu-font-color--hybrid,
    menuInverseFontColor: $menu-inverse-font--hybrid,
    textAreaWhiteBackground: $bg--hybrid-white-background,
    sideBarTextHighlight: $side-bar-text-highlight--hybrid,
    linkText: $link-text--hybrid,
    linkTextThread: $link-text--light,
    primaryActionButtonText: $primary-action-button-text--hybrid,
    primaryActionButtonBg: $primary-action-button-bg--hybrid,
    primaryActionButtonHoverBg: $primary-action-button-bg--hybrid,
    primaryActionButtonHoverColor: $bg--hybrid,
    primaryActionButtonDisabledText:
      $primary-action-button-disabled-text--hybrid,
    primaryActionButtonDisabledBg: $primary-action-button-disabled-bg--hybrid,
    defaultActionButtonText: $default-action-button-text--hybrid,
    defaultActionButtonBg: $default-action-button-bg--hybrid,
    defaultActionButtonDisabledText:
      $default-action-button-disabled-text--hybrid,
    defaultActionButtonDisabledBg: $default-action-button-disabled-bg--hybrid,
    primaryActionButtonTextSideBar: $primary-action-button-text-sidebar--hybrid,
    primaryActionButtonBgSideBar: $primary-action-button-bg-sidebar--hybrid,
    primaryActionButtonDisabledTextSideBar:
      $primary-action-button-disabled-text-sidebar--hybrid,
    primaryActionButtonDisabledBgSideBar:
      $primary-action-button-disabled-bg-sidebar--hybrid,
    defaultActionButtonTextSideBar: $default-action-button-text-sidebar--hybrid,
    defaultActionButtonBgSideBar: $default-action-button-bg-sidebar--hybrid,
    defaultActionButtonDisabledTextSideBar:
      $default-action-button-disabled-text-sidebar--hybrid,
    defaultActionButtonDisabledBgSideBar:
      $default-action-button-disabled-bg-sidebar--hybrid,
    sideBarColor: $sidebar-color--hybrid,
    sideBarColorHover: $sidebar-color-hover--hybrid,
    sideBarColorSelected: $sidebar-color-selected--hybrid,
    sideBarBackground: $sidebar-background-hover--hybrid,
    sideBarBackgroundHover: $sidebar-background-hover--hybrid,
    sideBarBackgroundSelected: $sidebar-background-selected--hybrid,
    sideBarDividerColor: $sidebar-divider-color--hybrid,
    sideBarUserProfileNameColor: $sidebar-user-profile-name-color--hybrid,
    sideBarUserProfileDetailsColor: $sidebar-user-profile-details-color--hybrid,
    sideBarUserProfileBackground: $sidebar-user-profile-background--hybrid,
    sideBarUserProfileIconColor: $sidebar-user-profile-icon-color--hybrid,
    nothingFoundLinkText: $primary-action-button-disabled-bg--hybrid,
    userProfileFullNameColor: $user-profile-full-name-color--hybrid,
    userProfileRoleColor: $user-profile-role-color--hybrid,
    userProfileMenuItemColor: $user-profile-menu-item-color--hybrid,
    previewDeviceBorderColor: $light-tone-contrast--light,
    threadItemsHoverColor: $thread-items-hover--hybrid,
    additionalRedText: $additional-red-text--hybrid,
    newContactIcon: $new-contact-icon--hybrid,
    checkStatusLabelBg: $bg--dark,
    modalMenuBg: $modal-menu-bg--hybrid,
  ),
  dark: (
    bg: $bg--dark,
    bgRHS: $bg-rhs--dark,
    bgLHS: $bg-LHS--dark,
    bgTransparent: $bg-transparent--dark,
    text: $text--dark,
    primary: $primary-color--dark,
    fontColor: $font-color--dark,
    fontDarkGray: $text--dark,
    iconColor: $icon-color--dark,
    iconMidGray: $rhs-icon-color--dark,
    lightToneContrast: $light-tone-contrast--dark,
    rhsIconColor: $rhs-icon-color--dark,
    rhsDividerColor: $font-normal-gray--dark,
    fontMidGray: $rhs-icon-color--dark,
    contactChipBorder: $rhs-icon-color--dark,
    fontNormalGray: $font-normal-gray--dark,
    cardBorder: $font-normal-gray--dark,
    tableHeader: $text--dark,
    label: $rhs-icon-color--dark,
    primaryHover: $primary-color-hover--dark,
    tableDivider: $bg-rhs--dark,
    leftSideMenu: $left-sidebar-menu--dark,
    leftMenu: $left-menu--dark,
    leftMenuText: $left-menu-text--dark,
    filterBackgroundHover: $filter-background-hover--dark,
    filterBackground: $filter-background--dark,
    filterText: $filter-text--dark,
    searchInputBackground: $search-input-background--dark,
    searchInputBorder: $search-input-border--dark,
    searchClear: $search-clear--dark,
    iconPurple: $icon-purple--dark,
    threadListBackground: $thread-list-background--dark,
    threadMessage: $left-menu-text--dark,
    threadCheckbox: $text--dark,
    threadActionIcon: $thread-action--dark,
    skeletonBody: $skeleton-body--dark,
    skeletonBodyGradient: $skeleton-body-gradient--dark,
    settingsMenuText: $rhs-icon-color--dark,
    nothingFoundText: $left-menu-text--dark,
    selectedThreadItem: $text--dark,
    incomingMessage: $incoming-message--dark,
    incomingMessageText: $incoming-message-text--dark,
    sentMessage: $sent-message--dark,
    textArea: $text-area--dark,
    avatarBackground: $avatar-background--dark,
    inverseFontColor: $inverse-font-color--dark,
    menuFontColor: $menu-font-color--dark,
    menuInverseFontColor: $menu-inverse-font--dark,
    textAreaWhiteBackground: $text-area--dark,
    sideBarTextHighlight: $side-bar-text-highlight--dark,
    linkText: $link-text--dark,
    linkTextThread: $link-text--dark,
    primaryActionButtonText: $primary-action-button-text--dark,
    primaryActionButtonBg: $primary-action-button-bg--dark,
    primaryActionButtonHoverBg: $bg-transparent--dark,
    primaryActionButtonHoverColor: $primary-action-button-bg--dark,
    primaryActionButtonDisabledText: $primary-action-button-disabled-text--dark,
    primaryActionButtonDisabledBg: $primary-action-button-disabled-bg--dark,
    defaultActionButtonText: $default-action-button-text--dark,
    defaultActionButtonBg: $default-action-button-bg--dark,
    defaultActionButtonDisabledText: $default-action-button-disabled-text--dark,
    defaultActionButtonDisabledBg: $default-action-button-disabled-bg--dark,
    primaryActionButtonTextSideBar: $primary-action-button-text--dark,
    primaryActionButtonBgSideBar: $primary-action-button-bg--dark,
    primaryActionButtonDisabledTextSideBar:
      $primary-action-button-disabled-text--dark,
    primaryActionButtonDisabledBgSideBar:
      $primary-action-button-disabled-bg--dark,
    defaultActionButtonTextSideBar: $default-action-button-text--dark,
    defaultActionButtonBgSideBar: $default-action-button-bg--dark,
    defaultActionButtonDisabledTextSideBar:
      $default-action-button-disabled-text--dark,
    defaultActionButtonDisabledBgSideBar:
      $default-action-button-disabled-bg--dark,
    sideBarColor: $sidebar-color--dark,
    sideBarColorHover: $sidebar-color-hover--dark,
    sideBarColorSelected: $sidebar-color-selected--dark,
    sideBarBackground: $sidebar-background-hover--dark,
    sideBarBackgroundHover: $sidebar-background-hover--dark,
    sideBarBackgroundSelected: $sidebar-background-selected--dark,
    sideBarDividerColor: $sidebar-divider-color--dark,
    sideBarUserProfileNameColor: $sidebar-user-profile-name-color--dark,
    sideBarUserProfileDetailsColor: $sidebar-user-profile-details-color--dark,
    sideBarUserProfileBackground: $sidebar-user-profile-background--dark,
    sideBarUserProfileIconColor: $sidebar-user-profile-icon-color--dark,
    nothingFoundLinkText: $primary-color--dark,
    userProfileFullNameColor: $user-profile-full-name-color--dark,
    userProfileRoleColor: $user-profile-role-color--dark,
    userProfileMenuItemColor: $user-profile-menu-item-color--dark,
    previewDeviceBorderColor: $skeleton-body-gradient--dark,
    threadItemsHoverColor: $thread-items-hover--dark,
    additionalRedText: $additional-red-text--dark,
    newContactIcon: $new-contact-icon--dark,
    checkStatusLabelBg: $bg--dark,
    modalMenuBg: $modal-menu-bg--dark,
  ),
  light: (
    bg: $bg--light,
    bgRHS: $bg-rhs--light,
    bgLHS: $bg--light,
    bgTransparent: $bg--light,
    text: $text--light,
    primary: $primary-color--light,
    fontColor: $font-color--light,
    fontMidGray: $font-mid-gray--light,
    fontDarkGray: $font-dark-gray--light,
    iconColor: $icon-color--light,
    iconMidGray: $icon-mid-gray--light,
    lightToneContrast: $light-tone-contrast--light,
    rhsIconColor: $rhs-icon-color--light,
    rhsDividerColor: $light-tone-contrast--light,
    contactChipBorder: $font-light-gray--light,
    fontNormalGray: $font-normal-gray--light,
    cardBorder: $font-light-gray--light,
    tableHeader: $rhs-icon-color--light,
    label: $font-dark-gray--light,
    primaryHover: $bg-rhs--light,
    tableDivider: $light-tone-contrast--light,
    leftSideMenu: $left-sidebar-menu--light,
    leftMenu: $left-menu--light,
    leftMenuText: $left-menu-text--light,
    filterBackgroundHover: $filter-background-hover--light,
    filterBackground: $filter-background--hybrid,
    filterText: $filter-text--hybrid,
    searchInputBackground: $search-input-background--hybrid,
    searchInputBorder: $search-input-border--hybrid,
    searchClear: $search-clear--hybrid,
    iconPurple: $icon-purple--hybrid,
    threadListBackground: $thread-list-background--light,
    threadMessage: $rhs-icon-color--light,
    threadCheckbox: $rhs-icon-color--light,
    threadActionIcon: $thread-action--light,
    skeletonBody: $skeleton-body--light,
    skeletonBodyGradient: $skeleton-body-gradient--light,
    settingsMenuText: $bg--light,
    nothingFoundText: $left-menu-text--light,
    selectedThreadItem: $left-menu-text--light,
    incomingMessage: $incoming-message--light,
    incomingMessageText: $font-color--light,
    sentMessage: $sent-message--light,
    textArea: $bg--light,
    avatarBackground: $avatar-background--light,
    inverseFontColor: $inverse-font-color--light,
    menuFontColor: $menu-font-color--light,
    menuInverseFontColor: $menu-inverse-font--light,
    textAreaWhiteBackground: bg--hybrid-white-background,
    sideBarTextHighlight: $side-bar-text-highlight--light,
    linkText: $link-text--light,
    linkTextThread: $link-text--light,
    primaryActionButtonText: $primary-action-button-text--light,
    primaryActionButtonBg: $primary-action-button-bg--light,
    primaryActionButtonHoverBg: $primary-action-button-bg--light,
    primaryActionButtonHoverColor: $bg--light,
    primaryActionButtonDisabledText: $primary-action-button-disabled-text--light,
    primaryActionButtonDisabledBg: $primary-action-button-disabled-bg--light,
    defaultActionButtonText: $default-action-button-text--light,
    defaultActionButtonBg: $default-action-button-bg--light,
    defaultActionButtonDisabledText: $default-action-button-disabled-text--light,
    defaultActionButtonDisabledBg: $default-action-button-disabled-bg--light,
    primaryActionButtonTextSideBar: $primary-action-button-text--light,
    primaryActionButtonBgSideBar: $primary-action-button-bg--light,
    primaryActionButtonDisabledTextSideBar:
      $primary-action-button-disabled-text--light,
    primaryActionButtonDisabledBgSideBar:
      $primary-action-button-disabled-bg--light,
    defaultActionButtonTextSideBar: $default-action-button-text--light,
    defaultActionButtonBgSideBar: $default-action-button-bg--light,
    defaultActionButtonDisabledTextSideBar:
      $default-action-button-disabled-text--light,
    defaultActionButtonDisabledBgSideBar:
      $default-action-button-disabled-bg--light,
    sideBarColor: $sidebar-color--light,
    sideBarColorHover: $sidebar-color-hover--light,
    sideBarColorSelected: $sidebar-color-selected--light,
    sideBarBackground: $sidebar-background--light,
    sideBarBackgroundHover: $sidebar-background-hover--light,
    sideBarBackgroundSelected: $sidebar-background-selected--light,
    sideBarDividerColor: $sidebar-divider-color--light,
    sideBarUserProfileNameColor: $sidebar-user-profile-name-color--light,
    sideBarUserProfileDetailsColor: $sidebar-user-profile-details-color--light,
    sideBarUserProfileBackground: $sidebar-user-profile-background--light,
    sideBarUserProfileIconColor: $sidebar-user-profile-icon-color--light,
    nothingFoundLinkText: $primary-color--light,
    userProfileFullNameColor: $user-profile-full-name-color--light,
    userProfileRoleColor: $user-profile-role-color--light,
    userProfileMenuItemColor: $user-profile-menu-item-color--light,
    previewDeviceBorderColor: $light-tone-contrast--light,
    threadItemsHoverColor: $thread-items-hover--light,
    additionalRedText: $additional-red-text--light,
    newContactIcon: $new-contact-icon--light,
    checkStatusLabelBg: $bg--dark,
    modalMenuBg: $modal-menu-bg--light,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
